.thing-form {
  margin: 20px 0;
}

#banner {
  border-bottom: none;
  margin-top: -20px;
}

#banner h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}

.hero-unit {
  position: relative;
  padding: 30px 15px;
  color: #F5F5F5;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background: #4393B9;
}

.navbar-text {
  margin-left: 15px;
}

.fixed-header #main {
    margin-top: 90px;
}

// We apply this when using theme [2]
.fixed-ribbon #content {
	padding-top: 5px;
}

#main {
    padding-bottom: 40px;
	//This overrides the default smart admin settings to allow the content to fill the height
	//header : 90px
	//footer : 15.6px
	min-height: calc(100vh - 105.6px) !important;
}
