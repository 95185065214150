//@import "~angular-aside/dist/css/angular-aside";

.page-header {
    background: rgba(255,255,255,1);
}
.navbar-text {
    margin-left: 15px;
}

// img.Crop {max-width:120px}
img.Crop {
    width: auto;
    height: 100px;
    border-radius: 3px;
}

.lower-canvas {
    position: relative !important;
}

.overlayed-container {
    position: relative;
    width: 100%;
    border:1px solid rgba(0, 0, 0, .25);
    border-radius: 5px;
    background-color: #ddd;
}

.overlayed-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.overlay-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.half-overlay {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: white;
}

.left {
    left: 0;
    width: 15%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255,255,255,0.7));
}

.right {
    right: 0;
    width: 15%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255,255,255,0.7));
}

.half-overlay:hover {
    opacity: 1;
}

.badgebtn {
    position: relative;
}

.imbadge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    padding: 1px 3px;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.photo-wrapper img {
    height:auto;
    max-width:100%;
    min-width:100%;
    cursor: pointer;
}

.snapshot-wrapper canvas {
    height:auto;
    max-width:100%;
    margin:0 10px 10px 0;
}

.alias {
    margin-top: 0.2em;
    margin-left: 0.5em;
    font-size: medium;
}

.photo {
    /*    background:#fff url(images/spinner.gif) center 30px no-repeat;*/
    border:1px solid rgba(0, 0, 0, .15);
    border-radius:3px;
    margin:0 10px 10px 0;
    position:relative;
}

.photo .photo-index {
    background:rgba(0, 0, 0, .9);
    color:#fff;
    position:absolute;
    height:100%;
    text-align:center;
    width:100%;
    z-index:99;
}

.photo .photo-index p:first-child {
    margin-top:10px;
}

.testOver {
    display: flex;
    flex-flow: column wrap;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 20%;
    left: 80%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.video-icon-overlay {
    cursor: pointer;
    font-size: 1.3em;
    margin: 0.5em 0 0.5em;
}
.photo-wrapper:hover .testOver {
    opacity: 1;
}
.hidden-panel{
    transition: display 0.5s visibility 0.5s;
    visibility: hidden;
    display: none;
    background-colour:#C6C9C5
}
.isVis{
    transition: display 0.5s visibility 0.5s;
    visibility: visible;
    display: block !important;

}

.filter-icon{
    padding: 0px 3px 0px 3px;
}

.photo-wrapper:hover .img-responsive {
    opacity: 0.5;
}


.photo .photo-wrapper {
    min-height:60px;
}

.photo .photo-description {
    padding:15px;
}

.loader {
    position: fixed;
    background: rgba(0, 0, 0, .15) url('/assets/images/hourglass.svg') no-repeat center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}


.flex-photos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: flex-start;
}

.video-icon {
    cursor: pointer;
}

.padded-table {
    td, th {
        padding: 0.5em;
    }
}

@media only screen and (min-width:500px) {
    .live-view-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 15px;

        .live-view-filter-types {
            margin-left: 15px;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    // Photo
    .flex-photos {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-content: flex-start;

        div:first-child {
            margin: 0;
        }

        div:last-child {
            margin: 0;
        }

        div:nth-of-type(2) {
            margin-left: 0;
        }

        .photo {
            border:1px solid rgba(0, 0, 0, .15);
            border-radius:3px;
            margin: 5px;
            position:relative;
            max-height: 305px;
            max-width: 15em;
        }

        .photo .photo-index {
            background:rgba(0, 0, 0, .9);
            color:#fff;
            position:absolute;
            height:100%;
            text-align:center;
            width:100%;
            z-index:99;
        }

        .photo .photo-index p:first-child {
            margin-top:10px;
        }

        .photo-wrapper img {
            //height:auto;
            //max-width:100%;
            min-width:100%;
            cursor: pointer;
            border-radius: 2px 2px 0 0;
            object-fit: contain;
            max-height: 180px;
            max-width: 15em;
        }

        img.Crop {
            width: auto;
            height: 100px;
            border-radius: 3px;
        }

        .photo-wrapper:hover .img-responsive {
            opacity: 0.5;
        }

        .photo .photo-wrapper {
            min-height:60px;
        }

        .photo .photo-description {
            padding:15px;

            .site-cam-mobile {
                display: none;
            }
            .site-cam-desktop {
                display: block;
            }

            .date-time-desktop {
                display: block;
            }
            .date-time-mobile {
                display: none;
            }
        }

        .photo-wrapper:hover .testOver {
            opacity: 1;
        }
    }

    // Modal
    .camera-button-container {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        .camera-function-buttons {
            display: flex;
            // width: 100%;
            flex-wrap: wrap;
            //justify-content: space-between;
            button {
                // flex-grow: 1;
                min-width: 120px;
                margin: 0px 5px 5px 0px;
            }
        }
        .camera-request-buttons {
            display: flex;
            flex-direction: row;
            margin-left: 5px;
            button {
                height: 40px;
                width: 40px;
            }
        }
    }
}

@media only screen and (max-width: 499px) {
    // Toolbar
    .live-view-filter {
        flex-wrap: wrap;

        .live-view-filter-types {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    .live-view-controls {
        margin-left: 10px;
    }

    // Card
    .flex-photos {
        flex-flow: column;

        .photo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: 0;
            margin-right: 0;

            .photo-wrapper {
                flex-grow: 1;
                padding: 5px;
                padding-right: 0px;

                img { border-radius: 1px; }
            }
            .photo-description {
                flex-grow: 20;
                padding: 5px;
                .site-cam-mobile {
                    display: flex;
                    margin-bottom: 5px;
                    flex-direction: row;
                    width: 100%;
                    small {
                        width: 50%;
                    }
                }
                .site-cam-desktop {
                    display: none;
                }
                .date-time-desktop {
                    display: none;
                }
                .date-time-mobile {
                    display: block;
                }
                div:nth-of-type(2) {
                    margin-left: 0px;
                }
            }
        }
    }

    // Modal
    .camera-button-container {
        .camera-function-buttons {
            //justify-content: space-between;
            button {
                margin: 0px 5px 5px 0px;
            }
        }
        .camera-request-buttons {
            flex-grow: 1;
        }
    }
}
