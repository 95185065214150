html {
	margin: 0;
	padding: 0;
	min-height: 100%;
	background: $html-background-color;
	position:relative;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100%;
	background: $body-background-color;
	direction:ltr;
}

/* when mobile menu is active */
body.mobile-view-activated.hidden-menu {
	overflow-x:hidden;
}

body.modal-open {
	overflow:hidden !important;
}

a:hover, a:active, a:focus, button, button:active, button:focus, object, embed, input::-moz-focus-inner {
  outline: 0;
}

h1,h2,h3,h4 {
	margin:0;
	font-family:"Open Sans", Arial, Helvetica, Sans-Serif;
	font-weight:300;
}

.page-title {
    margin: 12px 0 28px;
    span {
        font-size: $font-size-base+3;
        color: $gray-dark;
        display: inline-block;
        vertical-align: 1px;
    }
}

label {
	font-weight:normal;
}

*:focus {
    outline: 0 !important;
}

/*
 * IE FIX for tap delay
 */
a, input, button {
    -ms-touch-action: none !important;
}

textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */

  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

}

.input-sm,
.input-lg,
.input-xs,
.form-control {
		  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.input-xs {
	height: 24px;
	padding: 2px 10px;
	font-size: 11px;
	line-height: 1.5;
}

.btn-xs {
	padding: 1px 5px;
}

.btn-sm {
	padding: 6px 10px 5px;
}

.btn-lg {
	padding: 10px 16px;
}

.no-space {
  margin: 0;

  > [class*="col-"] {
    margin: 0 !important;
    padding-right: 0;
    padding-left: 0;
  }
}

#content {
	padding:10px 14px;
	position:relative;
	/*position:relative;
	left:0px;*/
}

body.container {
	position:absolute;
	left:0;
	right:0;
	padding: 0;
	border-left:1px solid $gray-mid-light;
	border-right:1px solid $gray-mid-light;
	box-shadow: 0 2px 70px rgba(red($black), green($black), blue($black), 0.45);
}

body.container.hidden-menu:not(.mobile-view-activated) {
  overflow:hidden;
}

body.container.modal-open {
	padding:0px !important;
}

/*
 * Headers
 */

h1 {
	letter-spacing:-1px;
	font-size:$font-size-h1;
	margin:10px 0;
}h1 small {
	font-size:$font-size-h4;
	font-weight:300;
	letter-spacing:-1px;
}.lead {
	font-size:$font-size-h4 + 1px;
}

h2 {
	letter-spacing:-1px;
	font-size: $font-size-h2;
	margin:20px 0;
	line-height:normal;
}

h3 {
	display: block;
	font-size: $font-size-h3;
	font-weight: 400;
	margin: 20px 0;
	line-height:normal;
}

h4 {
	line-height:normal;
}

h5 {
	font-size: $font-size-h5;
	font-weight: 300;
	margin: 10px 0;
	line-height:normal;
}

h6 {
	font-size: $font-size-h6;
	margin: 10px 0;
	font-weight:bold;
	line-height:normal;
}

.row-seperator-header {
	margin:15px 14px 20px;
	border-bottom:none;
	display:block;
	color: darken( $gray-light, 20.9%);
	font-size:20px;
	font-weight:400;
}

/*
 * Footer
 * see: component-page-footer.less
 */


/*
 * Center Canvas
 */
.center-canvas, .center-child-canvas > canvas {
	display:block !important;
	margin:0 auto !important;
}


/*
 * JUST A BORDER
 */
.bordered {
	border:1px solid rgba(red($black), green($black), blue($black),.2);
}.bordered:hover {
	border:1px solid rgba(red($black), green($black), blue($black),.4);
}



/*
 * ***************************************************
 * MAIN LAYOUT START
 * ***************************************************
 */

aside {
	display: block;
	/*overflow: hidden;*/
	background: $asideColor-end;
	background: url($asideDataURL);
	background: -moz-linear-gradient(left, $asideColor-end 93%, $asideColor-start 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(93%, $asideColor-end), color-stop(100%, $asideColor-start));
	background: -webkit-linear-gradient(left, $asideColor-end 93%, $asideColor-start 100%);
	background: -o-linear-gradient(left, $asideColor-end 93%, $asideColor-start 100%);
	background: -ms-linear-gradient(left, $asideColor-end 93%, $asideColor-start 100%);
	background: linear-gradient(to right, $asideColor-end 93%, $asideColor-start 100%);
	min-height: 100%;
}

/*
 * HEADER GROUP
 */


#header {
	display: block;
	height: $smart-navbar-height;
	margin: 0;
	padding: 0 13px 0 0;
	background-color: $smart-navbar-color-start;
	background-image: -moz-linear-gradient(top, $smart-navbar-color-start, $smart-navbar-color-end);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($smart-navbar-color-start), to($smart-navbar-color-end));
	background-image: -webkit-linear-gradient(top, $smart-navbar-color-start, $smart-navbar-color-end);
	background-image: -o-linear-gradient(top, $smart-navbar-color-start, $smart-navbar-color-end);
	background-image: linear-gradient(to bottom, $smart-navbar-color-start, $smart-navbar-color-end);
	background-repeat: repeat-x;
	position: relative;
	z-index: $left-panel-zindex+1;
}

#header > div {
	display: inline-block;
	vertical-align: middle;
	height: $smart-navbar-height;
	float:left;
}

#header > div.open {
	font-weight:bold;
}

.fixed-header #header {
	position:fixed;
	width:100%;
	z-index: $left-panel-zindex+1;
	top:0px;
	border-bottom:2px solid $gray-light;
	border-bottom: 1px solid rgba(0,0,0,.3);
	box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.1);
}

/* as per version 1.3 */
.fixed-header.fixed-ribbon #header {
	border-bottom:none;
	box-shadow:none;
}

.container.fixed-header #header {
	max-width: 1164px;
}

.fixed-header #header .ajax-dropdown {
	z-index: $left-panel-zindex+2;
}

.fixed-header #shortcut {
	position:fixed;
}

.fixed-header #main {
	margin-top:$smart-navbar-height;
}

#logo-group > span {
	display: inline-block;
	height: $smart-navbar-height - 10;
	float: left;
}

#logo-group span#activity {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	cursor: default !important;
	display: inline-block;
	font-weight: bold;
	height: 24px;
	width: 24px;
	padding: 2px;
	text-align: center;
	text-decoration: none !important;
	-moz-user-select: none;
	-webkit-user-select: none;
	background-color: lighten($gray-lightest, 1.9%);
	background-image: -webkit-gradient(linear,left top,left bottom,from(lighten($gray-lightest, 1.9%)),to(darken($gray-lightest, 1%)));
	background-image: -webkit-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -moz-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -ms-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -o-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));


	border: 1px solid $gray-mid-light;
	color: $dropdownbtn-icon-color;
	font-size: 19px;
	margin: 10px 0 0 0;
	position:relative;
}

#logo-group span#activity:hover {
	border: 1px solid $gray-mid-light;
	transition: all 0.0s;
	cursor: pointer !important;
	-webkit-box-shadow: inset 0px 0px 4px 1px rgba(red($black), green($black), blue($black), 0.08);
	box-shadow: inset 0px 0px 4px 1px rgba(red($black), green($black), blue($black), 0.08);
}

#logo {
	display: inline-block;
	width: $asideWidth - 45px;
	margin-top: 13px;
	margin-left: 9px;
}

	#logo img {
		width:110px;
		height:auto;
		padding-left:3px;
	}

	#activity b.badge {
		position: absolute;
		top: -5px;
		right: -5px;
		cursor: pointer;
		background:$blueSky;
		display: inline-block;
		font-size: 10px;
		box-shadow: inset 1px 1px 0 rgba(red($black), green($black), blue($black), 0.1), inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.07);
		color: $white;
		font-weight: bold;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		padding: 2px 4px 3px 4px;
		text-align: center;
		line-height: normal;
	}
	#activity.active .badge {
		background: darken($blueSky, 10%) !important;
	}

	#project-context,
	.project-context {
		display: inline-block;
		padding: 7px 13px 0;
		position:relative;
	}

	#project-context > span,
	.project-context > span {
		display: block;
	}

	#project-context > :first-child,
	.project-context > :first-child {
		display: block;
		color: $gray-mid-light;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
		text-shadow: 0 0 1px white;
		padding-left:0px;
		text-align:left;
	}

	#project-selector,
	.project-selector {
		max-width: 97%;
		white-space: nowrap;
		background: none;
		border: none;
		padding: 0;
		text-align: left;
		color: $gray;
		font-size: 14px;
		cursor: pointer;
	}

	#project-selector:hover,
	.project-selector:hover {
		color: $gray-dark;
	}

.header-search {
	position:relative;
}.header-search.pull-right {
	margin-left:6px;
}

.header-search > input[type="text"] {
	display: block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	min-width:200px;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	outline: none;
	border-width: 1px;
	border-style: solid;
	border-radius: 0;
	border-color:$gray-mid-light;
	background: $white;
	color: #404040;
	appearance: normal;
	-moz-appearance: none;
	-webkit-appearance: none;
	margin-top:10px;
	line-height:normal;
}

.header-search > button {
	background: none;
	border: none;
	color: #6D6A69;
	font-size: 17px;
	height: 30px;
	line-height: 30px;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0px;
	top: 10px;
	width: 30px;
	z-index: 2;
}

#search-mobile {
	display:none;
}

#cancel-search-js {
	position: absolute;
	display: none;
	background: $brand-danger;
	z-index: 3;
	color: $white;
	padding: 0;
	right: 10px;
	top: 10px;
	text-align: center;
	height: 29px;
	line-height: 29px;
	width: 30px;
	font-size: 17px;
	text-decoration:none !important;
}

#header > :first-child, aside {
	width: $asideWidth;
}


/*
 * LEFT PANEL
 */

#left-panel {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: $left-panel-zindex;
	padding-top:$smart-navbar-height;
}

/*
 * MAIN PANEL
 */

#main {
	margin-left: $asideWidth;
	padding: 0;
	padding-bottom:$smart-footer-height;
	min-height:500px;
	position: relative;
}


/*
 * RIBBON
 */

#ribbon {
	min-height: $ribbon-height;
	background: $ribbonBGColor;
	padding: 0 13px;
	position:relative;
}

#ribbon .breadcrumb {
	display: inline-block;
	margin: 0;
	padding: 11px 34px 11px 0 !important;
	background:none;
	vertical-align:top;
}

#ribbon .breadcrumb a, #ribbon .breadcrumb {
	color: #BBB !important;
	text-decoration:none !important;
}

#ribbon .breadcrumb > .active, #ribbon .breadcrumb li:last-child {
	color: #E4E4E4;
}

.fixed-ribbon #ribbon {
	position: fixed;
	right: 0;
	left: $asideWidth;
	top: $smart-navbar-height;
	z-index: $fixed-ribbon-zindex;
}

.container.fixed-ribbon #ribbon {
	width: 944px;
	left: 590px;
}

.fixed-ribbon #content {
	padding-top: $smart-navbar-height + 1;
}

.minified.fixed-ribbon #ribbon {
	left: 45px;
}

.hidden-menu.fixed-ribbon #ribbon {
	left:10px;
}

/*
 * NAV
 * Menu CSS is learnt from: http://experiments.wemakesites.net/css3-treeview.html
 */


.fixed-navigation #left-panel {
	height:100%;
	position:fixed;
	/*z-index: $fixed-navigation-zindex; */
}

/* Add this if not using slimscroll*/

.fixed-navigation nav {
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	height: 100%;
	padding-bottom: 49px;
}

.fixed-navigation nav > ul {
	padding-right: 15px;
	position: relative;
	height: 100%;
	width: 114%;
	overflow-x: hidden;
	overflow-y: scroll;
}

.mobile-detected.fixed-navigation nav > ul {
	padding-right: 30px !important;
}

/* Slimscrol CSS change: delete this if not using slimscroll

.fixed-navigation nav {
	padding-bottom: 49px;
}

.fixed-navigation nav > ul {
	position: relative;
}

.slimScrollBar,
.slimScrollRail {
	display:none !important;
}

.fixed-navigation .slimScrollBar,
.fixed-navigation .slimScrollRail {
	display:block !important;
}

.minified .slimScrollBar {
	display:none !important;
}*/

/* end slimscroll css change*/

.minified.fixed-navigation nav {
	-webkit-overflow-scrolling: none;
	overflow:visible;
	height: auto;
	padding-bottom: 0px;
}

.minified.fixed-navigation nav > ul {
	overflow:visible;
	padding-right:0px;
	width:100%;
}

/* All levels */
nav ul li a:active {
	background:$asideNavActiveBGColor !important;
}

/* All levels */
nav ul {
	width: 100%;
	padding: 40px 28px 25px 0;
	padding: 0;
	margin: 0;
	font-size: 13px;
	line-height: 0.5em;
	list-style: none;
	position: relative;
}

/* All levels */
nav ul .active > a {
	color: #fff !important;
	position: relative;
}

/* All levels */
nav ul li.active > a:before {
	content: "\f0d9";
	font-family: FontAwesome;
	display: block;
	height: 27px;
	line-height: normal;
	width: 27px;
	position: absolute;
	right: -21px;
	font-size: 20px;
	color:$gray-lighter;
}

nav ul li.active.open > a:before {
	content: "";
}

nav > ul > li:last-child.open {
	border-bottom:1px solid rgba(255,255,255,0.15);
}

/* All levels
nav ul li {
	overflow: hidden;
}*/

/* All levels */
nav ul li a {
	line-height: normal;
	font-size: $asideNavFontSize;
	padding: 10px 10px 10px 11px;
	color: $asideLinkColor;
	display: block;
	font-weight: normal;
	text-decoration: none !important;
	position:relative;
}

/* Second level */
nav > ul > li > ul > li > a {
	padding-top: 7px;
	padding-bottom: 7px;
}

/* Third Level */
nav > ul > li > ul > li > ul > li > a {
	padding-top: 7px;
	padding-bottom: 7px;
}

/* All levels */
nav ul li a:focus {
	color: $asideLinkColorFocus;
}

/* All levels */
nav ul li a:hover {
	color: $asideLinkColorHover;
	text-decoration: none;
}

nav ul li a.inactive,
nav ul li a.inactive:hover {
  opacity: .5;
  cursor: not-allowed;
  color: $asideLinkColor;
}

/* All levels */
nav ul b {
	float: right;
	font-size: $asideExpanIconSize;
	margin-top: $asideExpanIconMT;
}

/* All levels */
nav ul span.menu-item-parent {
	display: inline-block;
	margin: 0;
	padding: 0;
}

/* First level: A, Second level: LI Open */
nav ul li.open > a, nav ul li.open > a b {
	color: #fff !important;
}

/* First level: Icon */
nav > ul > li > a b {
	position: absolute !important;
	right: $asideNavExpanIconPush-x;
	top: $asideNavExpanIconPush-y;
}

nav > ul > li > a > i {
	/*font-size: 121%; fa-lg overrides this so..  */
	margin-right: 5px;
	width: 15px;
	display: inline-block;
	text-align: center;
	position:relative;
}
	/* First level icon */
	nav > ul > li > a > i > em {
		font-size: 9px;
		display: block;
		padding: 2px;
		position: absolute;
		top: -8px;
		right: -6px;
		text-decoration: none;
		font-style: normal;
		background: #ED1C24;
		color: $white;
		min-width: 13px;
		border-radius: 50%;
		max-height:13px;
		line-height: 8px;
		font-weight: bold;
		vertical-align: baseline;
		white-space: nowrap;
		text-align: center;
		border: 1px solid rgba(red($white), green($white), blue($white), 0.1);
	}


/* Second level switched to relative */
nav ul li li {
	border-bottom: none;
	position: relative;
}


/* Second level: UL */
nav > ul > li > ul::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	left: 23px;
	top: 0;
	bottom: 0;
	border-left: 1px solid #7A7A7A;
}

/* Second level: UL LI */
nav > ul ul li::before {
	content: "";
	display: block;
	position: absolute;
	width: 8px;
	left: 23px;
	top: 16px;
	border-top: 1px solid #7A7A7A;
	z-index: 1;
}

/* Third level: UL LI */
nav > ul ul ul li::before {
	content: "";
	display: block;
	position: absolute;
	width: 18px;
	left: 10px;
	top: 17px;
	border-top: 1px solid transparent;
}

/* Second level and onwards > */
nav > ul ul li a i, {
	font-size: $asideNavFontSize !important;
	width: 18px !important;
	text-align: center !important;
	vertical-align:0 !important;
	line-height:1 !important;
}

/* Second and Third level line color */
nav > ul > li:hover > ul::before, nav > ul > li:hover > ul > li::before{
	border-color: #ACACAC !important;
}


/* Second Level: UL */
nav ul ul {
	margin: 0;
	padding: 0;
	display: none;
	background: rgba(69, 69, 69,.6);
}

nav ul ul {
	padding: 7px 0;
}

/* Third Level: UL */
nav ul ul ul {
	background: transparent;
	padding: 0;
}

/* Third Level: LI */
nav ul ul li {
	margin: 0;
	padding: 0;
}

/* Second Level: A */
nav ul ul li > a {
	padding-left: 42px;
	font-size: $asideNavFontSizeSecondLevel;
	font-weight: normal;
	outline: 0;
}

/* Second Level: A:hover */
nav ul ul li > a:hover {
	background-color: rgba(69, 69, 69,.8);
	color: $white;
}

/* Third Level: A */
nav ul ul ul li a {
	color: $gray-mid-light;
	padding: 8px 10px 8px 60px;
	font-size: $asideNavFontSizeThirdLevel;
}

/* Third Level: A:hover */
nav ul ul ul li a:hover {
	color: $white;
}

/* Fourth Level: A */
nav ul ul ul ul li a {
	padding-left: 80px;
}

/* Fifth Level: A */
nav ul ul ul ul ul li a {
	padding-left: 100px;
}

/* Sixth Level: A */
nav ul ul ul ul ul ul li a {
	padding-left: 120px;
}

/* Second level: B */
nav ul ul b {
	color: #B3B3B3;
}

/* Second level: LI:hover B */
nav ul ul > li:hover b {
	color: #D5D9E2;
}

/*
 * HEADER NAV DROPDOWN LIST
 */

.header-dropdown-list {
    list-style:none;
    float:right;
    position:relative;
    margin:0px;
    padding-left:0px;
    padding-right:0px;
    & > li {
        display: inline-block;
        padding-right: 5px;
        & > .dropdown-toggle {
            margin-top:16px;
            display:block;
        }
    }
}



/*
 * ***************************************************
 * MAIN LAYOUT END
 * ***************************************************
 */



/*
 * ACCORDIONS
 */

.smart-accordion-default.panel-group {
	margin-bottom:0px;
}

.smart-accordion-default.panel-group .panel+.panel {
	margin-top:-1px;
}

.smart-accordion-default.panel-group .panel-heading {
	padding:0px;
}

.smart-accordion-default.panel-group .panel-title a {
	display: block;
	padding: 10px 15px;
	text-decoration: none !important;
}

.smart-accordion-default .panel-heading, .panel-group .panel {
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

.smart-accordion-default .panel-default>.panel-heading {
	background-color: lighten( $gray-lightest, 3.5%);
}

.smart-accordion-default  .panel-default {
	border-color:lighten( $gray-light, 16.5%);
}

.smart-accordion-default  .panel-title > a >:first-child{
	display:none;
}

.smart-accordion-default  .panel-title > a.collapsed > .fa{
	display:none;
}

.smart-accordion-default  .panel-title > a.collapsed >:first-child{
	display:inline-block;
}

.no-padding .smart-accordion-default > div {
	border-left:none !important;
	border-right:none !important;
}

.no-padding .smart-accordion-default > div:first-child {
	border-top:none !important;
}

.no-padding .smart-accordion-default > div:last-child {
	border-bottom:none !important;
}

/*
 * ON OFF SWITCHES
 */

/* http://proto.io/freebies/onoff/ */

.onoffswitch-container {
	margin-top:4px;
	margin-left:7px;
	display:inline-block;
}

.onoffswitch {
    position: relative;
    width: 50px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
    margin-top:3px;
    margin-bottom:3px;
    margin-left:5px;
    display:inline-block;
    vertical-align:middle;

}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid lighten($gray, 5%);
    border-radius: 50px;
    border-color: lighten($gray-light, 8%) lighten($gray-light, 10%) lighten($gray-light, 2%);
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    display:block;

}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 15px;
    padding: 0;
    line-height: 15px;
    font-size: 10px;
    color: $white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

}
.onoffswitch-inner:before {
    content: attr(data-swchon-text);
    text-shadow: 0 -1px 0 $gray-dark;
    padding-left: 7px;
    background-color: $onOffSwitchBG;
    color: $white;
    box-shadow: inset 0 2px 6px rgba(red($black), green($black), blue($black), 0.5), 0 1px 2px rgba(red($black), green($black), blue($black), 0.05);
    text-align:left;

}
.onoffswitch-inner:after {
    content: attr(data-swchoff-text);
    padding-right: 7px;
    text-shadow: 0 -1px 0 $white;
    background-color: $white;
    color: $gray;
    text-align: right;
    box-shadow:inset 0 2px 4px rgba(red($black), green($black), blue($black),.15), 0 1px 2px rgba(red($black), green($black), blue($black),.05);
}
.onoffswitch-switch {
	width: 19px;
	height:19px;
	margin: -2px;
	background: white;
	border: 1px solid lighten($gray-light, .5%);
	border-radius: 50px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 32px;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	background-color: $gray-lightest;
	background-image: -moz-linear-gradient(top, white, $gray-lighter);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to($gray-lighter));
	background-image: -webkit-linear-gradient(top, white, $gray-lighter);
	background-image: -o-linear-gradient(top, white, $gray-lighter);
	background-image: linear-gradient(to bottom, white, $gray-lighter);
	background-repeat: repeat-x;
	-webkit-box-shadow: 1px 1px 4px 0px rgba(red($black), green($black), blue($black), .3);
	box-shadow: 1px 1px 4px 0px rgba(red($black), green($black), blue($black), .3);
}

.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch:before, .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
	content: "\f00d";
	color:$brand-danger;
	display:block;
	text-align:center;
	line-height:19px;
	font-size: 10px;
	text-shadow: 0 -1px 0 $white;
	font-weight:bold;

	font-family:FontAwesome;
}

/* checked */
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
	content: "\f00c";
	color: $blue;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
    display:block;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

.onoffswitch-switch:hover {
	background-color: $gray-lighter;
}
.onoffswitch-switch:active {
	background-color: $gray-lighter;
	box-shadow:inset 0 2px 4px rgba(red($black), green($black), blue($black),.15), 0 1px 2px rgba(red($black), green($black), blue($black),.05);
}

/*
 * Disabled state
 */
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:after, .onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-inner:before {
	text-shadow: 0 1px 0 $white;
	background: $gray-mid-light;
	color: $gray-dark;
}

.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-switch, .onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
	background-color: $gray-lightest;
	background-image: -moz-linear-gradient(top, $gray-mid-light, $gray-lightest);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($gray-mid-light), to($gray-lightest));
	background-image: -webkit-linear-gradient(top, $gray-mid-light, $gray-lightest);
	background-image: -o-linear-gradient(top, $gray-mid-light, $gray-lightest);
	background-image: linear-gradient(to bottom, $gray-mid-light, $gray-lightest);
	box-shadow: none !important;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-label {
	border-color: lighten($gray-light, 7%) $gray-light darken($gray-light, 7%) !important;
}

.onoffswitch-checkbox:checked + .onoffswitch-label{
	border-color: $onOffSwitchBG darken($onOffSwitchBG, 7%) darken($onOffSwitchBG, 12%);
}


.onoffswitch + span, .onoffswitch-title {
	display: inline-block;
	vertical-align: middle;
	margin-top: -5px;
}


/*
 * FORMS
 */

.form-control {
	box-shadow:none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}

.form hr {
	margin-left:-13px;
	margin-right:-13px;
	border-color:rgba(red($black), green($black), blue($black), 0.1);
	margin-top: 20px;
	margin-bottom: 20px;
}

.form fieldset {
	display: block;
	border: none;
	background: rgba(red($white), green($white), blue($white), 0.9);
	position: relative;
}

 fieldset {
	position:relative;
}

.form-actions {
	display: block;
	padding: 13px 14px 15px;
	border-top: 1px solid rgba(red($black), green($black), blue($black), 0.1);
	background: rgba(red(lighten($gray-lightest, 2%)), green(lighten($gray-lightest, 2%)), blue(lighten($gray-lightest, 2%)), 0.9);
	margin-top:25px;
	margin-left: -13px;
	margin-right: -13px;
	margin-bottom: -13px;
	text-align:right;

}.well .form-actions {
	margin-left: -19px;
	margin-right: -19px;
	margin-bottom: -19px;
}.well.well-lg .form-actions {
	margin-left: -24px;
	margin-right: -24px;
	margin-bottom: -24px;
}.well.well-sm .form-actions {
	margin-left: -9px;
	margin-right: -9px;
	margin-bottom: -9px;
}

.popover-content .form-actions {
	margin:0 -14px -9px;
	border-radius:0 0 3px 3px;
	padding:9px 14px
}

.no-padding .form .form-actions {
	margin:0;
	display: block;
	padding: 13px 14px 15px;
	border-top: 1px solid rgba(red($black), green($black), blue($black), 0.1);
	background: rgba(248, 248, 248, 0.9);
	text-align:right;
	margin-top:25px;
}

.form header, legend, .bootstrap-duallistbox-container label {
	display: block;
	padding: 8px 0;
	border-bottom: 1px dashed rgba(red($black), green($black), blue($black), 0.2);
	background: $white;
	font-size: 16px;
	font-weight: 300;
	color:  $gray-darker;
	margin: 25px 0px 20px;
} .no-padding .form header {
	margin: 25px 14px 0;
}.form header:first-child {
	margin-top:10px;
} legend {
	font-weight:400;
	margin-top:0px;
	background:none;
}

.input-group-addon {
	padding: 6px 10px;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}

.input-group-addon {
	transition: border-color 0.3s;
	-o-transition: border-color 0.3s;
	-ms-transition: border-color 0.3s;
	-moz-transition: border-color 0.3s;
	-webkit-transition: border-color 0.3s;

	transition: background-color 0.3s;
	-o-transition: background-color 0.3s;
	-ms-transition: background-color 0.3s;
	-moz-transition: background-color 0.3s;
	-webkit-transition: background-color 0.3s;
}

.input-group-addon .fa {
	font-size:14px;
}

.input-group-addon .fa-lg, .input-group-addon .fa-2x {
	font-size: 2em;
}

.input-group-addon .fa-3x, .input-group-addon .fa-4x, .input-group-addon .fa-5x {
	font-size: 30px;
}


input[type="text"]:focus + .input-group-addon {
	border-color: $blueSky;
	background-color: lighten($state-info-text, 23%);
	color:$white;
}

.has-warning input[type="text"], .has-warning input[type="text"] + .input-group-addon {
	border-color: $state-warning-border;
}.has-warning input[type="text"] + .input-group-addon {
	background-color: darken($state-warning-bg, 5%);
	color:$state-warning-text;
}
.has-warning input[type="text"]:focus, .has-warning input[type="text"]:focus + .input-group-addon {
	border-color: $state-warning-border;
}.has-warning input[type="text"]:focus + .input-group-addon {
	background-color: darken($state-warning-bg, 45%);
	color:$white;
}

.has-error .input-group-addon {
	border-color: $state-danger-border;
	background:$state-danger-bg;
	color:$state-danger-text;
}
.has-error input[type="text"], .has-error input[type="text"] + .input-group-addon {
	border-color: $state-danger-border;
}.has-error input[type="text"] + .input-group-addon {
	background-color: $state-danger-bg;
	color:$state-danger-text;
}
.has-error input[type="text"]:focus, .has-error input[type="text"]:focus + .input-group-addon {
		border-color: darken($state-danger-bg, 35%);
}.has-error input[type="text"]:focus + .input-group-addon {
	background-color: darken($state-danger-bg, 35%);
	color:$white;
}


.has-success input[type="text"]:focus + .input-group-addon {
	border-color:$state-success-border;
	background-color:$state-success-border;
	color:$white;
}

.form fieldset .form-group:last-child, .form fieldset .form-group:last-child .note,
.form .form-group:last-child, .form .form-group:last-child .note {
	margin-bottom:0;
}

.note {
	margin-top: 6px;
	padding: 0 1px;
	font-size: 11px;
	line-height: 15px;
	color: $gray-light;
}

	/*
	 * WITH ICON
	 */
	.input-icon-right {
		position:relative;
	}
	.input-icon-right > i, .input-icon-left > i {
		position:absolute;
		right:10px;
		top:30%;
		font-size:16px;
		color:$gray-mid-light;
	}

	.input-icon-left > i {
		right:auto;
		left:24px;
	}

	.input-icon-right .form-control {
		padding-right:27px;
	}

	.input-icon-left .form-control {
		padding-left:29px;
	}

	input[type="text"].ui-autocomplete-loading,
	input[type="password"].ui-autocomplete-loading,
	input[type="datetime"].ui-autocomplete-loading,
	input[type="datetime-local"].ui-autocomplete-loading,
	input[type="date"].ui-autocomplete-loading,
	input[type="month"].ui-autocomplete-loading,
	input[type="time"].ui-autocomplete-loading,
	input[type="week"].ui-autocomplete-loading,
	input[type="number"].ui-autocomplete-loading,
	input[type="email"].ui-autocomplete-loading,
	input[type="url"].ui-autocomplete-loading,
	input[type="search"].ui-autocomplete-loading,
	input[type="tel"].ui-autocomplete-loading,
	input[type="color"].ui-autocomplete-loading {
		background-image: url("#{$base-url}/select2-spinner.gif") !important;
		background-repeat: no-repeat;
		background-position: 99% 50%;
		padding-right:27px
	}

	.input-group-addon .checkbox, .input-group-addon .radio {
		min-height: 0px;
		margin-right:0px !important;
		padding-top:0;
	}

	.input-group-addon label input[type="checkbox"].checkbox + span,
	.input-group-addon label input[type="radio"].radiobox + span,
	.input-group-addon label input[type="radio"].radiobox + span:before,
	.input-group-addon label input[type="checkbox"].checkbox + span:before {
		margin-right:0px;
	}

	.input-group-addon .onoffswitch , .input-group-addon .onoffswitch-label{
		margin:0;
	}


/*
 * Timeline Seperator
 */

.timeline-seperator {
    display:block;
    text-align:center;
    color:$gray-light;
    margin:20px 0 0;
    & > :first-child {
        padding:4px 5px;
        border:1px dashed rgba(red($black), green($black), blue($black), 0.1);
        background:$white;
        font-size:11px;
        border-radius:4px;
    }
}.timeline-seperator .btn, .timeline-seperator .btn + .dropdown-menu {
    margin-right:15px;
}

.timeline-seperator:after {
    display:block;
    content:" ";
    margin:0 20px;
    border-bottom:1px dashed rgba(red($black), green($black), blue($black), 0.1);
    margin-top:-8px;
}

/*
 * Alerts
 */
.alert {
    margin-bottom: 20px;
    margin-top: 0px;
    padding: 5px 15px 5px 34px;
    color:#675100;
    border-width:0px;
    border-left-width:5px;
    padding:10px;
    border-radius:0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    .close {
        top: 0px;
        right: -5px;
        line-height: 20px;
    }

}

.alert-heading {
    font-weight:600;
}

.alert-danger {
    border-color: darken($state-danger-border, 10%);
    color: $white;
    background-color: darken($state-danger-bg, 33%);
    text-shadow:none;
}

.alert-warning {
    border-color:lighten($state-warning-border,5%);
    color: darken($state-warning-text, 19%);
    background-color: spin(lighten($state-warning-border, 22%), 8);
}

.alert-success {
    border-color: lighten($state-success-border,25%);
    color: darken($state-success-text,10%);
    background-color: spin(darken(lighten($state-success-text, 50%), 8%), -20);
}

.alert-info {
    border-color: $state-info-border;
    color: darken($state-info-text, 3%);
    background-color: $state-info-bg;
}

/*
 * WELLS
 */

.well {
	background-color: darken($white, 1.5%);
	border: 1px solid darken($gray-lighter, 7%);
	box-shadow: 0 1px 1px darken($gray-lighter, 1%);
	-webkit-box-shadow: 0 1px 1px darken($gray-lighter, 1%);
	-moz-box-shadow: 0 1px 1px darken($gray-lighter, 1%);
	position:relative;
}

.well.well-clean {
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
	border-color:transparent;
}

.well.well-glass {
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
	border-color:transparent;
	background-color:rgba(red($white), green($white), blue($white),.25);
	color:$white;
}

.well.well-light {
	background: $white;
	border: 1px solid darken($gray-lighter, 5%);
}

.widget-body {
    .well {
        margin-bottom:0px;
    }
}

.well.transparent {
	border:none !important;
	background:none !important;
}

.jarviswidget.well.transparent > div, .jarviswidget.well.transparent > div .widget-body {
	padding:0px !important;
}

/* any wells with custom bg the border will be darkened */
.well[class^="bg-"], .well[class*=" bg-"]{
	border: 1px solid $gray !important;
}

/*
 * TABS
 */
.nav-tabs>li > a .badge {
	font-size: 11px;
	padding: 3px 5px 3px 5px;
	opacity: .5;
	margin-left: 5px;
	min-width: 17px;
	font-weight: normal;
}
.nav-tabs>li > a > .fa {
	opacity: .5;
}
.tabs-left .nav-tabs>li > a .badge {
	margin-right:5px;
	margin-left:0px;
}
.nav-tabs>li > a .label {
	display:inline-block;
	font-size:11px;
	margin-left:5px;
	opacity: .5;
}
.nav-tabs>li.active > a .badge, .nav-tabs>li.active > a .label, .nav-tabs>li.active > a > .fa{
	opacity: 1;
}
.nav-tabs>li>a {
	border-radius:0px;
	color:$gray-dark;
}
.nav-tabs > li.active > a {
	-webkit-box-shadow: 0 -2px 0 $blue;
	-moz-box-shadow: 0 -2px 0 $blue;
	box-shadow: 0 -2px 0 $blue;
	border-top-width: 0px !important;
	margin-top: 1px !important;
	font-weight:bold;
}
.tabs-left .nav-tabs > li.active > a {
	-webkit-box-shadow: -2px 0 0 $blue;
	-moz-box-shadow: -2px 0 0 $blue;
	box-shadow: -2px 0 0 $blue;
	border-top-width: 1px !important;
	border-left: none !important;
	margin-left: 1px !important;
}
.tabs-left .nav-pills > li.active > a {
	border:none !important;
	box-shadow:none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
}
.tabs-right .nav-tabs > li.active > a {
	-webkit-box-shadow: 2px 0 0 $blue;
	-moz-box-shadow: 2px 0 0 $blue;
	box-shadow: 2px 0 0 $blue;
	border-top-width: 1px !important;
	border-right: none !important;
	margin-right: 1px !important;
}
.tabs-below .nav-tabs > li.active > a {
	-webkit-box-shadow: 0 2px 0 $blue;
	-moz-box-shadow: 0 2px 0 $blue;
	box-shadow: 0 2px 0 $blue;
	border-bottom-width: 0px !important;
	border-top: none !important;
	margin-top: 0px !important;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
	border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
	display: none;
}

.tab-content > .active, .pill-content > .active {
	display: block;
}

.tabs-below > .nav-tabs {
	border-top: 1px solid darken($gray-lighter, 7%);
}

.tabs-below > .nav-tabs > li {
	margin-top: -1px;
	margin-bottom: 0;
}

.tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li,
.tabs-left > .nav-pills > li, .tabs-right > .nav-pills > li {
	float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a,
.tabs-left > .nav-pills > li > a, .tabs-right > .nav-pills > li > a {
	min-width: 74px;
	margin-right: 0;
	margin-bottom: 3px;
}

.tabs-left > .nav-tabs, .tabs-left > .nav-pills {
	float: left;
	margin-right: 19px;
	border-right: 1px solid darken($gray-lighter, 7%);
} .tabs-left > .nav-pills {
	border-right:none;
}

.tabs-left > .nav-tabs > li > a {
	margin-right: -1px;
}

.tabs-left > .nav-tabs > li > a:hover, .tabs-left > .nav-tabs > li > a:focus {
	border-color: $gray-lighter darken($gray-lighter, 10%) $gray-lighter $gray-lighter;
}

.tabs-left > .nav-tabs .active > a, .tabs-left > .nav-tabs .active > a:hover, .tabs-left > .nav-tabs .active > a:focus {
	border-color: darken($gray-lighter, 10%) transparent darken($gray-lighter, 10%) darken($gray-lighter, 7%);
	*border-right-color: $white;
}

.tabs-left > .tab-content {
	margin-left: 109px;
}

.tabs-right > .nav-tabs {
	float: right;
	margin-left: 19px;
	border-left: 1px solid darken($gray-lighter, 7%); /* DDD */
}

.tabs-right > .nav-tabs > li > a {
	margin-left: -1px;
}

.tabs-right > .nav-tabs > li > a:hover, .tabs-right > .nav-tabs > li > a:focus {
	border-color: $gray-lighter $gray-lighter $gray-lighter darken($gray-lighter, 7%);
}

.tabs-right > .nav-tabs .active > a, .tabs-right > .nav-tabs .active > a:hover, .tabs-right > .nav-tabs .active > a:focus {
	border-color: darken($gray-lighter, 7%) darken($gray-lighter, 7%) darken($gray-lighter, 7%) transparent;
	*border-left-color: $white;
}

.tabs-below > .nav-tabs, .tabs-right > .nav-tabs, .tabs-left > .nav-tabs {
	border-bottom: 0;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
	display: none;
}

.tab-content > .active, .pill-content > .active {
	display: block;
}

.tabs-below > .nav-tabs {
	border-top: 1px solid darken($gray-lighter, 7%);
}

.tabs-below > .nav-tabs > li {
	margin-top: -1px;
	margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
	border-top-color: darken($gray-lighter, 7%);
	border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover, .tabs-below > .nav-tabs > .active > a:focus {
	border-color: transparent darken($gray-lighter, 7%) darken($gray-lighter, 7%) darken($gray-lighter, 7%); /* tabs */
}

/* bordered tabs */
.nav-tabs.bordered {
	background:$white;
	border:1px solid darken($gray-lighter, 7%);
}

.nav-tabs.bordered >:first-child a{
	border-left-width:0px !important;
}

.nav-tabs.bordered + .tab-content {
	border:1px solid darken($gray-lighter, 7%);
	border-top:none;
}

/* bordered tabs top right */

.tabs-pull-right.nav-tabs>li, .tabs-pull-right.nav-pills>li {
	float:right;
}

.tabs-pull-right.nav-tabs>li:first-child > a, .tabs-pull-right.nav-pills>li:first-child > a {
	margin-right:1px;
}

.tabs-pull-right.bordered.nav-tabs>li:first-child > a, .tabs-pull-right.bordered.nav-pills>li:first-child > a {
	border-left-width:1px !important;
	margin-right:0px;
	border-right-width:0px;
}

/* jarviswidget header first child is tab */

.jarviswidget > header > .nav-tabs.pull-left > li:first-child a{
	border-left-width:0px !important;
}


/*
 * WEBKIT IOS HIGHLIGHT COLOR
 *
 */
a:link, .btn, button {
	-webkit-tap-highlight-color: fade($red, 50%);
}

.login-info, .login-info *, #logo-group *, .minified .menu-item-parent {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}


/*
 * BS2 useful classes
 * these classes and its importance was overlooked in BS3
 */

.hidden-desktop {
	display:none !important;
}

/*
 * DROPDOWN MENU
 */
.dropdown-menu-xs {
	min-width:37px;
}

.dropdown-menu-xs>li>a {
	padding: 3px 10px;
}.dropdown-menu-xs>li>a:hover i {
	color:#fff !important;
}

/* Dropdown menu extended */
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: $gray-mid-light;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: $white;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

/*
 * Table
 */

.table.has-tickbox thead tr th:first-child {
	width:18px;
}

.table.has-tickbox.smart-form thead tr th:first-child .radio,
.table.has-tickbox.smart-form thead tr th:first-child .checkbox,
.table.has-tickbox.smart-form tbody tr td:first-child .radio,
.table.has-tickbox.smart-form tbody tr td:first-child .checkbox {
	padding:0px !important;
}

.table thead tr,
.fc-border-separate thead tr {
	background-color: $gray-lighter;
	background-image: -webkit-gradient(linear,0 0,0 100%,from($table-header-color-start),to($table-header-color-end));
	background-image: -webkit-linear-gradient(top,$table-header-color-start 0,$table-header-color-end 100%);
	background-image: -moz-linear-gradient(top,$table-header-color-start 0,$table-header-color-end 100%);
	background-image: -ms-linear-gradient(top,$table-header-color-start 0,$table-header-color-end 100%);
	background-image: -o-linear-gradient(top,$table-header-color-start 0,$table-header-color-end 100%);
	background-image: -linear-gradient(top,$table-header-color-start 0,$table-header-color-end 100%);
	font-size: 12px;
}
.fc-border-separate thead tr th {
		padding: 4px;
		line-height: 1.428571429;
}

.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
	border-width:1px;
}

.no-padding > .table-bordered,
.no-padding > .table-responsive > .table-bordered {
	border:none !important;
}

.no-padding > .table-bordered thead tr th:first-child,
.no-padding > .table-bordered thead tr td:first-child,
.no-padding > .table-bordered tbody tr td:first-child,
.no-padding > .table-responsive > .table-bordered thead tr th:first-child,
.no-padding > .table-responsive > .table-bordered thead tr td:first-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:first-child  {
	border-left-width:0px !important;
}
.no-padding > .table-bordered thead tr th:last-child,
.no-padding > .table-bordered tbody tr td:last-child,
.no-padding > .table-responsive > .table-bordered thead tr th:last-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:last-child {
	border-right-width:0px !important;
}

.no-padding > .table-bordered tbody tr:last-child td,
.no-padding > .table-responsive > .table-bordered tbody tr:last-child td {
	border-bottom-width:0px !important;
}

.no-padding .table-responsive .table{
	margin-bottom:0;
}

.no-padding .note-editable .table-bordered {
	border: 1px solid darken($gray-lighter, 7%) !important;
}

.smart-form.table td .radio i,
.smart-form.table td .checkbox i,
.smart-form.table th .radio i,
.smart-form.table th .checkbox i {
	top: 0px !important;
}

/* condenced table */

.table-condensed.table>tbody>tr>td,
.table-condensed.table>tbody>tr>th,
.table-condensed.table>tfoot>tr>td,
.table-condensed.table>tfoot>tr>th,
.table-condensed.table>thead>tr>td,
.table-condensed.table>thead>tr>th {
	padding: 5px 10px !important;
}


/*
 * small stats
 */

.show-stats .progress {
	height: 7px;
	border-radius: 0;
	margin-top: 3px;
	margin-bottom: 15px;
}

.show-stats {
	padding-top:6px !important;
}

.show-stat-buttons {
	float:left;
	width:100%;
}

.show-stats .show-stat-buttons>:first-child{
	padding-right:5px;
}
.show-stats .show-stat-buttons>:last-child{
	padding-left:5px;
}

.show-stat-microcharts {
	margin-left:-10px;
	margin-right:-10px;
}

.show-stat-microcharts > div {
	border-right: 1px solid #DADADA !important;
	border-top: 1px solid #DADADA !important;
	margin-top: 10px !important;
	height: 65px;
	overflow:hidden;
	padding: 7px 9px;
}

.show-stat-microcharts >:last-child {
	border-right:none !important;
}

.show-stat-microcharts .sparkline {
	margin-top:10px;
	margin-right:8px;
	opacity:.7;
} .show-stat-microcharts .sparkline:hover {
	opacity:1;
}

.smaller-stat {
	margin:0;
	padding:0;
	list-style:none;
}

.smaller-stat li {
	margin-top:3px;
	margin-right:0px;
}.smaller-stat li:first-child {
	margin-top:8px;
}

.smaller-stat span.label {
	width:40px;
	display:block;
	text-align:left;
	opacity:.5;
	cursor: default;
} .smaller-stat span.label:hover {
	opacity:1;
}

/*
 * Login info
 */

.login-info {
	border-bottom: 1px solid #D8D4D4;
	display: block;
	font-size: 12px;
	height: 39px;
	color: $white;
	border: solid transparent;
	border-width: 1px 0px 1px 0px;
	box-shadow: inset 1px 1px 0 rgba(red($black), green($black), blue($black), 0.1), inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.07);
	width: 100%;
	margin: 0px !important;
	border-bottom: 1px solid #525151;
}

.login-info a {
	text-decoration:none !important;
	color: $asideLinkColor;
	display: inline-block;
	margin-top: 6px;
}

.login-info a span {
	text-transform: capitalize;
	font-size: $font-size-base + 1px; /* 14px~ */
	display: inline-block;
	text-decoration: none;
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
.login-info a span + i {
	vertical-align:middle;
}

.login-info a:hover {
	color: $white;
}

.login-info > span {
	height: 38px;
	display: block;
	padding: 0 10px;
	border-bottom: 1px solid #1A1817;
}

.login-info img {
	width: 25px;
	height: auto;
	display: inline-block;
	vertical-align: middle;
	margin-top: 1px;
	margin-right: 5px;
	margin-left: 0px;
	border-left: 3px solid $white;
}

img.online, img.busy, img.offline, img.away {
	border-left:3px solid #fff;
}

img.online {
	border-left-color: $greenBright !important;
}
img.busy {
	border-left-color: $redBright !important;
}
img.offline {
	border-left-color: darken($gray-lighter, 7%) !important;
}
img.away {
	border-left-color: $orangeBright !important;
}

	/*
	 * Client page forms
	 */
		.client-form header {
			padding: 15px 13px;
			margin:0;
			border-bottom-style:solid;
			border-bottom-color:rgba(red($black), green($black), blue($black), .1);
			background: rgba(248, 248, 248, .9);
		}


/*
 * MINIFIED MENU
 */

.minified .inbox-badge {
	border-radius: 50%;
	font-size: 9px;
	padding: 2px;
	position: absolute;
	top: 6px;
	right: 6px;
	background: #ED1C24;
	min-width:13px;
}

.minifyme {
  border-bottom: 1px solid #302F2F;
  background: #454545;
  color: #A8A8A8;
  text-align: center;
  padding: 0;
  width: 36px;
  height: 28px;
  display: block;
  position: absolute;
  right: 0px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  margin-top: 7px;
  font-size: 19px;
  transition: all 0.1s linear 0s;
}
.minifyme:hover {
  background: #646464;
  color: #E4E4E4;
  right: 0px;
  width: 40px;
  padding-right: 5px;
}

.minified .minifyme {
	right: 0px;
	color: #A8A8A8;
	position: relative;
	width: 100%;
	border-radius: 0px;
	margin: 0;
	height: 34px;
	font-size: 23px;
}

.minified .minifyme .fa:before {
	content:"\f0a9";
}

.minified .login-info a span, .minified .login-info a i {
	display: none;
}

.minified .login-info span {
	padding: 0px 5px;
	text-align:center;
}
.minified .login-info img {
	margin: -2px 0 0;
	width: 30px;
}

.minified .login-info {
	height: 38px;
	border-bottom: 1px solid #181818;
}

.minified #left-panel {
	width: 45px;
}

.minified #main {
	margin-left: 45px;
}

/*
 * Minified NAV
 */

.minified #left-panel, .minified nav > ul > li {
	overflow: visible;
}

.minified nav > ul > ul {
	min-height: 100px !important;
	max-height: 180px !important;
	overflow-y: scroll;
	overflow-x: hidden;
	background: #ed1c24 !important;
}

.minified nav > ul > li, .minified nav > ul > li a {
	position: relative;
}

.minified nav ul > li > a {
	padding: 10px 11px;
	display:block
}

.minified nav > ul > li > a .fa.fa-fw {
	display: block;
	width: auto;
	text-align: center;
	padding:0;
}

.minified nav > ul > li > a > i {
	text-align:center;
	margin:0;
	display:block;
}

.minified nav > ul > li.active > a::before {
	content: "\f0d9";
}

.minified nav ul ul li a {
	padding-left: 14px;
}
.minified nav ul ul li li a {
	padding-left: 25px;
}

.minified nav > ul ul li::before {
	left: 12px;
}
.minified nav > ul > li > ul::before {
	left: 12px;
}

.minified nav > ul > li > a .menu-item-parent, .minified nav > ul > li > a > b {
	display: none;
}

.minified nav > ul > li > a > .menu-item-parent {
	display: none;
	position: absolute;
	left: 40px;
	top: -3px;
	width: $asideWidth - 34px;
	height: 38px;
	line-height: 38px;
	background-color: whiteSmoke;
	color: $gray-dark;
	z-index: 3;
	-webkit-box-shadow: 2px 1px 2px 0 rgba(red($black), green($black), blue($black), 0.2);
	-moz-box-shadow: 2px 1px 2px 0 rgba(red($black), green($black), blue($black),0.2);
	box-shadow: 2px 1px 2px 0 rgba(red($black), green($black), blue($black), 0.2);
	border-left: 1px solid $gray-mid-light;
	border-top: 1px solid #D8D4D4;
	border-bottom: 1px solid $white;
	padding-left: 12px;
}

.minified nav > ul > li > ul {
	display: none !important;
	position: absolute;
	left: 40px;
	width: $asideWidth - 21px;
	z-index: 5;
	border: 1px solid $gray-mid-light;
	min-height: 180px;
	-webkit-box-shadow: 1px 1px 2px 0 rgba(red($black), green($black), blue($black), 0.2);
	-moz-box-shadow: 1px 1px 2px 0 rgba(red($black), green($black), blue($black),0.2);
	box-shadow: 1px 1px 2px 0 rgba(red($black), green($black), blue($black), 0.2);
	background: rgba(69,69,69,1);
	margin-top: -3px;
	overflow:hidden;
}


.minified nav ul > li > ul > li > ul {
	position: relative;
	left: 13px;
	border: none;
	width: auto;
	margin-left: -12px;
	width: $asideWidth - 23px;
}

.minified nav > ul > li:hover > a > .menu-item-parent, .minified nav > ul > li:hover > ul {
	display: block !important;
}

.minified nav > ul > li > ul > li {
	background-color: rgba(69, 69, 69, .9);
}
.minified nav > ul > li > ul > li > ul > li {
	background-color: #484B50;
}

.minified nav > ul > li {
	border-bottom: 1px solid #1A1817;
	border-top: 1px solid #525151;
}

.minified nav > ul > li > ul::before {
	border-left: none;
}

.minified nav > ul ul li::before {
	border: none;
}

.slimScrollDiv, .slimScrollDiv > :first-child {
	overflow: hidden;
}

.minified .slimScrollDiv, .minified .slimScrollDiv > :first-child {
	overflow: visible !important;
}

.minified .slimScrollBar, .minified .slimScrollRail {
	display: none;
}

.minified nav > ul > li > ul > li > a {
	padding-top: 7px;
	padding-bottom: 7px;
}

.minified nav > ul > li > ul > li > ul > li > a {
	padding-top: 7px;
	padding-bottom: 7px;
}

.minified nav > ul > li > ul > li > ul > li a {
	padding-top: 6px;
	padding-bottom: 6px;
}

/* Fourth Level: A */
.minified nav ul ul ul ul li a {
	padding-left: 45px;
}

/* Fifth Level: A */
.minified nav ul ul ul ul ul li a {
	padding-left: 62px;
}

/* Sixth Level: A */
.minified nav ul ul ul ul ul ul li a {
	padding-left: 82px;
}


/*
 * ALTERNATIVE PAGING
 */

.pagination.pagination-alt>li>a {
	box-shadow:  none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	border: none;
	margin-left: -1px;
}
.pagination.pagination-alt > li:first-child>a {
	padding-left:0px;
}

/*
 * CUSTOM BUTTON
 */

.pagination > li > a, .pagination > li > span {
	box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
	-moz-box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
	-webkit-box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
}

.disabled {
	color:$white;
}
.btn-default.disabled {
	color:$gray-light;
}

.btn {
	border-radius:2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
	-moz-box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
	-webkit-box-shadow: inset 0 -2px 0 rgba(red($black), green($black), blue($black), 0.05);
}
.btn:active {
	position:relative;
	top:1px;
	left:1px;
}

.btn.btn-ribbon {
	background-color: #707070;
	background-image: -moz-linear-gradient(top, #777, #666);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#777), to(#666));
	background-image: -webkit-linear-gradient(top, #777, #666);
	background-image: -o-linear-gradient(top, #777, #666);
	background-image: linear-gradient(to bottom, #777, #666);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff777777', endColorstr='#ff666666', GradientType=0);
	color: white;
	padding: 0 5px;
	line-height: 20px;
	vertical-align: middle;
	height: 20px;
	display: block;
	border: none;
	float: left;
	margin: 0 8px 0 0;
	cursor: pointer;
}

.btn.btn-ribbon > i {
	font-size:111%;
}

.ribbon-button-alignment {
	padding-top: 10px;
	display: inline-block;
}

.ribbon-button-alignment.pull-right > .btn.btn-ribbon {
	margin: 0 0 0 8px;
}


/*
 * Panels
 */
.panel-purple {
    border-color:$purple;
    & > .panel-heading {
        color: $white;
        background-color: $purple;
        border-color: $purple;
    }
}
.panel-greenLight {
    border-color:$greenLight;
    & > .panel-heading {
        color: $white;
        background-color: $greenLight;
        border-color: $greenLight;
    }
}
.panel-greenDark {
    border-color:$greenDark;
    & > .panel-heading {
        color: $white;
        background-color: $greenDark;
        border-color: $greenDark;
    }
}
.panel-darken {
    border-color:$gray-dark;
    & > .panel-heading {
        color: $white;
        background-color: #404040;
        border-color: #404040;
    }
}
.panel-pink {
    border-color:$pink;
    & > .panel-heading {
        color: $white;
        background-color: $pink;
        border-color: $pink;
    }
}
.panel-green {
    border-color:$green;
    & > .panel-heading {
        color: $white;
        background-color: $green;
        border-color: $green;
    }
}
.panel-blueLight {
    border-color: $blueLight;
    & > .panel-heading {
        color: $white;
        background-color: $blueLight;
        border-color: $blueLight;
    }
}
.panel-pinkDark {
    border-color:$pinkDark;
    & > .panel-heading {
        color: $white;
        background-color: $pinkDark;
        border-color: $pinkDark;
    }
}
.panel-redLight {
    border-color: $redLight;
    & > .panel-heading {
        color: $white;
        background-color: $redLight;
        border-color: $redLight;
    }
}
.panel-red {
    border-color: $red;
    & > .panel-heading {
        color: $white;
        background-color: $red;
        border-color: $red;
    }
}
.panel-teal {
    border-color: $teal;
    & > .panel-heading {
        color: $white;
        background-color: $teal;
        border-color: $teal;
    }
}
.panel-orange {
    border-color: $orange;
    & > .panel-heading {
        color: $white;
        background-color: $orange;
        border-color: $orange;
    }
}
.panel-blueDark {
    border-color: $blueDark;
    & > .panel-heading {
        color: $white;
        background-color: $blueDark;
        border-color: $blueDark;
    }
}
.panel-magenta {
    border-color:$magenta;
    & > .panel-heading {
        color: $white;
        background-color: $magenta;
        border-color: $magenta;
    }
}
.panel-blue {
    border-color: $blue;
    & > .panel-heading {
        color: $white;
        background-color: $blue;
        border-color: $blue;
    }
}


.panel-footer > .btn-block {
	border-radius:0px;
	-moz-border-radius:0px;
	-webkit-border-radius: 0px;
	border-bottom:none;
	border-left:none;
	border-right:none;
}

/*
 * Circle Buttons
 */

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 15px;
  font-size: 24px;
  line-height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}


/*
 * Big Metro Buttons
 */

.btn-metro {
	margin:0 0 20px;
	padding-top:15px;
	padding-bottom:15px;
}

.btn-metro > span {
	display:block;
	vertical-align: bottom;
	margin-top:10px;
	text-transform:uppercase;
}

.btn-metro > span.label {
	position: absolute;
	top:0px;
	right:0px;
}

/*
 * buttons with labels
 */

.btn-label {position: relative;left: -12px;display: inline-block;padding: 6px 12px;background: rgba(red($black), green($black), blue($black),0.15);border-radius: 3px 0 0 3px;}
.btn-labeled {padding-top: 0;padding-bottom: 0;}

.btn-link {
	box-shadow: none;
	-webkit-box-shadow: none;
	font-size: $font-size-base + 1;
}

/*
 * ANIMATION
 */

#left-panel {
	-webkit-transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
	transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
	-webkit-transform: translate(0px, 0px) translateZ(0px);
}

/* removed as it was too jerky */
/*#main {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}*/

/*
 * CHARTS
 */

.chart-small, .chart-large, .chart-xl {
	overflow:hidden;
}

.chart-small {
	height:100px;
	width:100%;
}

.chart-large {
	height:235px;
	width:100%;
}

.chart-xl {
	height:297px;
	width:100%;
}

/*
 * Flot Tool tips
 */

#flotTip {
	padding: 3px 5px;
	background-color: $white;
	z-index: $tooltip-zindex;
	color: $gray-dark;

	-webkit-box-shadow: 0 1px 8px rgba(red($black), green($black), blue($black), 0.1);
	-moz-box-shadow: 0 1px 8px rgba(red($black), green($black), blue($black),0.1);
	box-shadow: 0 1px 8px rgba(red($black), green($black), blue($black), 0.1);
	font-size: 14px;
	border: 1px solid #C1C1C1;

	-moz-border-radius: 4px;
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

#flotTip span {
	color:#38812D;
	font-weight:bold;
}


.legendLabel span {
	display: block;
	margin: 0 5px;
}
.legendColorBox {
	padding-left: 10px;
	vertical-align: top;
	padding-top: 5px;
}
.legendColorBox div > div {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	box-sizing:content-box;
	-moz-box-sizing:content-box;
	-webkit-box-sizing: content-box;
}


/*
 * Morris Charts
 */

.morris-hover.morris-default-style {
    border-radius: 10px;
    padding: 6px;
    color: #666;
    background: rgba(red($white), green($white), blue($white), 0.8);
    border: solid 2px rgba(230, 230, 230, 0.8);
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
    .morris-hover-row-label {
        font-weight: bold;
        margin: 0.25em 0;
    }
    .morris-hover-point {
        white-space: nowrap;
        margin: 0.1em 0;
    }
}
.morris-hover {
    position: absolute;
    z-index: $morris-hover;
}
.fixed-page-footer .morris-hover {
	 z-index: $fixed-footer-zindex - 1;
}

/*
 * Flot Chart
 */

.chart {
	overflow: hidden; /*- disabled for widget chrome bug */
	height:220px;
	margin:20px 5px 10px 10px;
}

.has-legend {
	margin-top: 30px !important;
}

.has-legend-unique {
	margin-top: 19px !important;
}


/*
 * Carren colors
 */

.icon-color-good {
	color:$greenBright;
}

.icon-color-bad {
	color:$redBright;
}

/*
 * SIGNS
 */
.percent-sign:after, .degree-sign:after {
	content: " %";
	line-height: normal;
}

.degree-sign:after {
	content:" °F";
}

/*
 * HIDDEN MENU
 */
.hidden-menu #left-panel {
	left: -$asideWidth + 10px;
	z-index: $hidden-lefthand-menu-zindex;
}
.hidden-menu #main {
	margin-left: 10px;
}

.hidden-menu #left-panel:hover {
	left: 0px;
}

.hidden-menu .minifyme {
	display: none;
}

.minified #hide-menu {
	display: none;
}

.btn-header.pull-right {
	margin-left:6px;
}

.btn-header a > span {
	font-size:13px;
	font-weight:normal;
	line-height:30px;
	height:30px;
	display:inline-block;
}

.btn-header > :first-child > a {
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	cursor: default !important;
	display: inline-block;
	font-weight: bold;
	height: 30px;
	line-height: 24px;
	min-width: 30px;
	padding: 2px;
	text-align: center;
	text-decoration: none !important;
	-moz-user-select: none;
	-webkit-user-select: none;
	background-color: lighten($gray-lightest, 1.9%);
	background-image: -webkit-gradient(linear,left top,left bottom,from(lighten($gray-lightest, 1.9%)),to(darken($gray-lightest, 1%)));
	background-image: -webkit-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -moz-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -ms-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: -o-linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	background-image: linear-gradient(top,lighten($gray-lightest, 1.9%),darken($gray-lightest, 1%));
	border: 1px solid $gray-mid-light;
	color: #6D6A69;
	font-size: 17px;
	margin: 10px 0 0 0;
}

.btn-header > :first-child > a:hover {
	border: 1px solid $gray-mid-light;
	color: #222;
	transition: all 0.0s;
	cursor: pointer;
	-webkit-box-shadow: inset 0px 0px 4px 1px rgba(red($black), green($black), blue($black), .08);
	box-shadow: inset 0px 0px 4px 1px rgba(red($black), green($black), blue($black), .08);
}

.btn-header > :first-child > a:active {
	background-color: #e8e8e8;
	background-image: -moz-linear-gradient(top,  #e8e8e8 0%, #ededed 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e8e8e8), color-stop(100%,#ededed));
	background-image: -webkit-linear-gradient(top,  #e8e8e8 0%,#ededed 100%);
	background-image: -o-linear-gradient(top,  #e8e8e8 0%,#ededed 100%);
	background-image: -ms-linear-gradient(top,  #e8e8e8 0%,#ededed 100%);
	background-image: linear-gradient(to bottom,  #e8e8e8 0%,#ededed 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#ededed',GradientType=0 );
	-webkit-box-shadow: inset 0px 0px 3px 1px rgba(red($black), green($black), blue($black), .15);
	box-shadow: inset 0px 0px 3px 1px rgba(red($black), green($black), blue($black), .15);
}

.hidden-menu #hide-menu > :first-child > a {
	background-color: #e8e8e8;
	background-image: -moz-linear-gradient(top,  #5A5A5A 0%, #686868 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#5A5A5A), color-stop(100%,#686868));
	background-image: -webkit-linear-gradient(to bottom, #5A5A5A 0%,#686868 100%);
	background-image: -o-linear-gradient(to bottom, #5A5A5A 0%,#686868 100%);
	background-image: -ms-linear-gradient(to bottom, #5A5A5A 0%,#686868 100%);
	background-image: linear-gradient(to bottom, #5A5A5A 0%,#686868 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5A5A5A', endColorstr='#686868',GradientType=0 );
	-webkit-box-shadow: inset 0px 0px 3px 1px rgba(red($black), green($black), blue($black), .15);
	box-shadow: inset 0px 0px 3px 1px rgba(red($black), green($black), blue($black), .15);
	color: $white;
	border-color:#494949;
}

.hidden-menu #hide-menu > :first-child > a:active, .full-screen #fullscreen > :first-child > a:active {
	-webkit-box-shadow: inset 0px 0px 6px 1px rgba(red($black), green($black), blue($black), .2);
	box-shadow: inset 0px 0px 6px 1px rgba(red($black), green($black), blue($black), .2);
}

/*
 * AJAX DROPDOWN
 */

.ajax-dropdown >:first-child {
	margin:0 0 3px 0;
	padding:0 0 9px 0;
}

.ajax-dropdown {
	position: absolute;
	display: none;
	z-index: $ajax-dropdown-zindex;
	background: white;
	top: 48px;
	left: 16px;
	width: 344px;
	height: 435px;
	border-radius:0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	-webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
	-moz-box-shadow: 0 2px 4px rgba(30,30,100,0.25);
	box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
	padding:10px;
	background: $white;
	border: 1px solid #b3b3b3;
}

.ajax-dropdown:after, .ajax-dropdown:before {
	bottom: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.ajax-dropdown:after {
	border-color: rgba(red($white), green($white), blue($white), 0);
	border-bottom-color: $ajaxdrop-arrow-top-color;
	border-width: $ajaxdropdown-arrow-size;
	left: 50%;
	margin-left: -$ajaxdropdown-arrow-size;
}

.ajax-dropdown:before {
	border-color: rgba(131, 131, 131, 0);
	border-bottom-color: $ajaxdrop-arrow-bottom-color;
	border-width: $ajaxdropdown-arrow-bottom;
	left: 50%;
	margin-left: -$ajaxdropdown-arrow-bottom;
}

.ajax-dropdown .btn-group .btn {
	font-weight:bold;
	text-transform:capitalize;
}

/* better for 3 childs*/

.ajax-dropdown .btn-group >:nth-child(2) {
	border-right-width:0px;
	border-left-width:0px;
}

.ajax-dropdown .btn-group >:last-child {
	border-right-width:1px !important;
}

/* better for 4+ childs
.ajax-dropdown .btn-group >:first-child {
	border-right-width:1px;
}

.ajax-dropdown .btn-group >:nth-child(n+2) {
	border-left-width:0px;
}

.ajax-dropdown .btn-group >:last-child {
	border-right-width:1px !important;
}	*/

.ajax-dropdown .btn-group .btn:active {
	top:0px;
	left:0px;
}

.ajax-notifications {
	height: $ajaxdropdown-height;
	display:block;
	overflow: auto;
	margin-right: -10px;
	margin-left: -10px;
	border-bottom: 1px solid #CECECE;
	border-top: 1px solid #CECECE;
	background: #E9E9E9;
}

.ajax-notifications  .alert.alert-transparent {
	background-color: transparent;
	border-color: transparent;
	color: #757575;
	margin:13px 10px;
}

.ajax-notifications .alert>:first-child {
	margin-bottom:10px;
	font-weight:400;
}

.ajax-dropdown >:last-child {
	font-size:13px;
	display: block;
	padding: 5px 0;
	line-height: 22px;
	font-weight: 400;
}

.ajax-dropdown .fa-4x.fa-border {
	border-width:3px;
	border-radius:50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	display:block;
	margin:0 auto;
	width:46px;
	text-align:center;
	color: #D1D1D1;
	border-color:#D1D1D1;
}

/*
 * LARGE DROPDOWNS
 */

.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0 ;
    margin-right: 0 ;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}



/*
 * PAGING
 */

.pagination-xs>li>a, .pagination-xs>li>span {
	padding: 4px 9px;
	font-size:12px;
}

/*
 * NOTIFICATIONS : MAIL
 */

.notification-body *, .notification-body:before *, .notification-body:after * {
	box-sizing:border-box !important;
	-webkit-box-sizing:border-box !important;
	-moz-box-sizing:border-box !important;
}

.notification-body {
	padding:0 0 0 3px;
	margin:0;
	list-style:none;
}

.notification-body > li {
	border-bottom: 1px solid #E9E9E9;
	position:relative;
}


.notification-body > li > span {
	background: $white;
	display: block;
	min-height: 25px;
	overflow: hidden;
	padding: 8px 10px 13px;
	white-space: normal;
}

.notification-body > li > span:hover {
	background: #F0F4F7 ;
	color: #667F8F;
}

.notification-body > li a.msg {
	padding-left:50px !important;
}


.notification-body > li a, .notification-body > li a:hover {
text-decoration: none;
}

.notification-body > li a, .notification-body > li a span {
	display:block;
	position:relative;
	overflow:hidden;
}

.notification-body > li a, .notification-body > li a:hover {
	text-decoration:none;
}

.notification-body > li:hover .progress {
	background: $white;
	box-shadow: 0 1px 0 rgba(red($black), green($black), blue($black), 0), 0 0 0 1px #A7A7A7 inset;
	-webkit-box-shadow: 0 1px 0 rgba(red($black), green($black), blue($black), 0), 0 0 0 1px #CCC inset;
	-moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten($gray-lighter, 10%) inset;
}.notification-body > li:hover .text-muted {
	color:$gray-dark;
}

.notification-body .from {
	font-size: $font-size-base + 1px;
	line-height:normal;
	color:$gray-dark;
	font-weight:normal;
	margin-right:60px;
	margin-bottom:6px;
	height:20px;
	text-overflow:ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.notification-body .unread .from {
	font-weight:bold;
}

.notification-body .unread {
	background:#FFFFE0;
}

.notification-body time {
	position:absolute;
	top:3px;
	right:0;
	font-size: 11px;
	font-weight:normal;
	color:#058dc7;
}

.notification-body .msg-body, .notification-body .subject {
	font-size: $font-size-base;
	color:#A0A0A0;
	max-height:35px;
	width: 100%;
	text-overflow:ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.notification-body .subject {
	font-size: $font-size-base;
	color:$gray-dark;
	max-height:22px;
}


/*
 * WIZARDS
 */

.bootstrapWizard {
	display: block;
	list-style: none;
	padding: 0;
	position: relative;
	width: 100%;
}
.bootstrapWizard a:hover, .bootstrapWizard a:active, .bootstrapWizard a:focus {
	text-decoration: none
}
.bootstrapWizard li {
	display: block;
	float: left;
	width: 25%;
	text-align: center;
	padding-left: 0;
}
.bootstrapWizard li:before {
	border-top: 3px solid #55606E;
	content: "";
	display: block;
	font-size: 0;
	overflow: hidden;
	position: relative;
	top: 11px;
	right: 1px;
	width: 100%;
	z-index: 1;
}
.bootstrapWizard li:first-child:before {
	left: 50%;
	max-width: 50%;
}
.bootstrapWizard li:last-child:before {
	max-width: 50%;
	width: 50%;
}

.bootstrapWizard li.complete .step {
	background: $wizard-step-complete;
	padding: 1px 6px;
	border: 3px solid #55606E;
}
.bootstrapWizard li  .step i {
	font-size: 10px;
	font-weight: normal;
	position: relative;
	top: -1.5px;
}
.bootstrapWizard li .step {
	background: #B2B5B9;
	color: $white;
	display: inline;
	font-size: 15px;
	font-weight: bold;
	line-height: 12px;
	padding: 7px 13px;
	border: 3px solid transparent;
	border-radius: 50%;
	line-height: normal;
	position: relative;
	text-align: center;
	z-index: 2;
	transition: all 0.1s linear 0s;
}
.bootstrapWizard li.active .step, .bootstrapWizard li.active.complete .step {
	background: $wizard-step-active;
	color: $white;
	font-weight: bold;
	padding: 7px 13px;
	font-size: 15px;
	border-radius: 50%;
	border: 3px solid #55606E;
}
.bootstrapWizard li.complete .title, .bootstrapWizard li.active .title {
	color: #2B3D53;
}
.bootstrapWizard li .title {
	color: $gray-mid-light;
	display: block;
	font-size: 13px;
	line-height: 15px;
	max-width: 100%;
	position: relative;
	table-layout: fixed;
	text-align: center;
	top: 20px;
	word-wrap: break-word;
	z-index: 104;
}

.wizard-actions {
	display: block;
	list-style: none;
	padding: 0;
	position: relative;
	width: 100%;
}
.wizard-actions li {
	display: inline;
}

.tab-content.transparent {
	background-color: transparent;
}

/*
 * FUELUX WIZARD
 */

.fuelux .wizard {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 4px rgba(red($black), green($black), blue($black), 0.065);
     -moz-box-shadow: 0 1px 4px rgba(red($black), green($black), blue($black), 0.065);
          box-shadow: 0 1px 4px rgba(red($black), green($black), blue($black), 0.065);
}

.fuelux .wizard:before,
.fuelux .wizard:after {
  display: table;
  line-height: 0;
  content: "";
}

.fuelux .wizard:after {
  clear: both;
}

.fuelux .wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.fuelux .wizard ul.previous-disabled li.complete {
  cursor: default;
}

.fuelux .wizard ul.previous-disabled li.complete:hover {
  color: $green;
  cursor: default;
  background: #f3f4f5;
}

.fuelux .wizard ul.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}

.fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999999;
  cursor: default;
  background: #ededed;
}

.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}

.fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
}

.fuelux .wizard ul li.complete {
  color: $state-success-text;
  background: #f3f4f5;
}

.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: #e7eff8;
}

.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}

.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}

.fuelux .wizard ul li.active {
  color: #3a87ad;
  background: #f1f6fc;
}

.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}

.fuelux .wizard ul li .badge {
  margin-right: 8px;
}

.fuelux .wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}

.fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: $gray-lighter;
  border-left: 1px solid #d4d4d4;
}

.fuelux .wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}

.fuelux .wizard .actions .btn-prev i {
  margin-right: 5px;
}

.fuelux .wizard .actions .btn-next i {
  margin-left: 5px;
}

.fuelux .step-content .step-pane {
  display: none;
}

.fuelux .step-content .active {
  display: block;
}

.fuelux .step-content .active .btn-group .active {
  display: inline-block;
}


/*
 * jarvismetro TILE
 */

.jarvismetro-tile {
	float:left;
	display: block;
	background-color: $white;
	width: 100px;
	height: 70px;
	cursor: pointer;
	box-shadow: inset 0px 0px 1px #FFFFCC;
	border:1px dotted #C5C5C5;
	text-decoration: none;
	color: $white;
	position: relative;
	font-weight: 300;
	font-smooth: always;
	margin: 0 10px 20px 0;
	padding:5px;
	position: relative;
}

.jarvismetro-tile:hover {
	z-index: 10;
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
}

.jarvismetro-tile.big-cubes {
	width:120px;
	height:120px;
}

.jarvismetro-tile.double {
	width: 249px;
}

.jarvismetro-tile:active {
	top: 1px;
	left: 1px;
}

.jarvismetro-tile .iconbox {
	text-align:center;
}

.jarvismetro-tile .iconbox i {
	display:block;
	margin: 15px auto 0;
	height: 75px;
}
.jarvismetro-tile .iconbox span {
	display:block;
	text-align:left;
}

.jarvismetro-tile .iconbox span > span {
	position: absolute;
	right: -3px;
	bottom: -3px;
	border-radius: 50%;
	padding: 5px 5px;
	border: 1px solid $white;
}

#shortcut .selected {
	border: 1px solid $blueSky !important;
}

#shortcut .selected {
	position:relative;
}

#shortcut .selected:before {
	display:block;
	position: absolute;
	content: "\f00c";
	color: $white;
	right: 4px;
	top: 3px;
	font-family: FontAwesome;
	z-index: 2;
}

#shortcut .selected:after {
	display:block;
	width: 0;
	height: 0;
	border-top: 35px solid $blueSky;
	border-left: 35px solid rgba(red($black), green($black), blue($black), 0);
	position: absolute;
	display: block;
	right: 0;
	content: "";
	top: 0;
	z-index: 1;
}

/*
 * SHORT CUT
 */

#shortcut {
	display:block;
	position:absolute;
	top:0px;
	left:0;
	height:auto;
	width:100%;
	background-color:#33302F;
	background-color: rgba(red($black), green($black), blue($black), 0.85);
	z-index: $ajax-dropdown-zindex+2;
	display:none;
	color:$white;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

#shortcut ul {
	padding: 15px 20px 10px;
	list-style:none;
	margin:0;
	box-shadow: 0px 4px 10px rgba(red($black), green($black), blue($black), 0.3);
	-moz-box-shadow: 0px 4px 10px rgba(red($black), green($black), blue($black), 0.3);
	border-bottom: 1px solid #423F3F;
}

#shortcut ul li {
	display:inline-block;
	width:auto;
}

#shortcut ul li .jarvismetro-tile  {
	margin:0px 3px 3px;
	border:none;
	border-radius: 0px;
	-moz-border-radius:0px;
	-webkit-border-radius:0px;
}

#shortcut ul li .jarvismetro-tile:hover {
	color:$white;
	text-decoration:none;
}

#shortcut ul li .jarvismetro-tile:active, #shortcut ul li .jarvismetro-tile:focus {
	left:0;
	top:0;
}

.shortcut-on #response-btn {
	display:none !important;

}

.shortcut-on #main .navbar, .shortcut-on #left-bar .navbar {
	border:none !important;
}

/*
 * ARROW BOX
 */


.arrow-box-up:after, .arrow-box-up:before {
	bottom: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow-box-up:after {
	border-color: rgba(red($white), green($white), blue($white), 0);
	border-bottom-color: $white;
	border-width: 7px;
	left: 50%;
	margin-left: -7px;
}

.arrow-box-up:before {
	border-color: rgba(131, 131, 131, 0);
	border-bottom-color: #838383;
	border-width: 8px;
	left: 50%;
	margin-left: -8px;
}

	/*
	 * right
	 */

	.arrow-box-up-right:after, .arrow-box-up-right:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.arrow-box-up-right:after {
		border-color: rgba(red($white), green($white), blue($white), 0);
		border-bottom-color: $white;
		border-width: 7px;
		right: 10px;
		margin-left: -7px;
	}

	.arrow-box-up-right:before {
		border-color: rgba(131, 131, 131, 0);
		border-bottom-color: #838383;
		border-width: 8px;
		right: 9px;
		margin-left: -8px;
	}

	/*
	 * BOOTSTRAP TOOLTIP
	 */
	.tooltip-inner {
		padding: 5px 10px;
		font-size:$tooltip-font-size;
		font-family:Arial, Helvetica, sans-serif;
		font-weight:bold;
		border-radius: 0px;
	}


/*
 * WEBKIT SCROLLING CSS
 * Reference: http://stackoverflow.com/questions/10592657/scrollbar-stylesheet
 * GitHub Source: https://gist.github.com/jambu/2004633
 */
.custom-scroll::-webkit-scrollbar {
	-webkit-overflow-scrolling: touch;
	height: 10px;
	width: 10px;
}

	.custom-scroll::-webkit-scrollbar:hover {
		background-color: #E9E9E9;
		border: 1px solid #dbdbdb;
	}

.custom-scroll::-webkit-scrollbar-button:start:decrement,
.custom-scroll::-webkit-scrollbar-button:end:increment {
	background: transparent;
	display: block;
	height: 0;
}

.custom-scroll::-webkit-scrollbar-track {
	background-clip: padding-box;
	border-width: 0 0 0 4px;
	border: solid transparent;
}

.custom-scroll::-webkit-scrollbar-track-piece {
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	-webkit-border-radius: 0;
	background-color: transparent;
	border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb {
	-moz-box-shadow: inset 1px 1px 0 rgba(red($black), green($black), blue($black),0.1), inset 0 -1px 0 rgba(red($black), green($black), blue($black),0.07);
	-webkit-box-shadow: inset 1px 1px 0 rgba(red($black), green($black), blue($black),0.1), inset 0 -1px 0 rgba(red($black), green($black), blue($black),0.07);
	background-clip: padding-box;
	background-color: rgba(red($black), green($black), blue($black),0.2);
	border-width: 0;
	border: none;
	box-shadow: inset 1px 1px 0 rgba(red($black), green($black), blue($black),0.1), inset 0 -1px 0 rgba(red($black), green($black), blue($black),0.07);
}

.custom-scroll::-webkit-scrollbar-thumb:vertical,
.custom-scroll::-webkit-scrollbar-thumb:horizontal {
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	-webkit-border-radius: 0;
	background-color: $gray-mid-light;
	border-radius: 0;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
	-moz-box-shadow: inset 1px 1px 3px rgba(red($black), green($black), blue($black),0.33);
	-webkit-box-shadow: inset 1px 1px 3px rgba(red($black), green($black), blue($black),0.33);
	background-color: rgba(red($black), green($black), blue($black),0.44);
	box-shadow: inset 1px 1px 3px rgba(red($black), green($black), blue($black),0.33);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
	background-color: #959595;
}


/*
 * Typehead
 */

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  height: 32px;
  padding: 6px 12px;
}

.tt-dropdown-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: $white;
  border: 1px solid $gray-mid-light;
  border: 1px solid rgba(red($black), green($black), blue($black),.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-box-shadow: 0 5px 10px rgba(red($black), green($black), blue($black),.2);
     -moz-box-shadow: 0 5px 10px rgba(red($black), green($black), blue($black),.2);
          box-shadow: 0 5px 10px rgba(red($black), green($black), blue($black),.2);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
}

.tt-suggestion.tt-is-under-cursor {
  color: $white;
  background-color: #0081c2;
}

.tt-suggestion.tt-is-under-cursor a {
  color: $white;
}

.tt-suggestion p {
  margin: 0;
}


/*
 * Google Maps
 */

.google_maps {
	width:100%;
	height:350px;
	position:relative;
}

.google_maps * {
	box-sizing:content-box;
	-webkit-box-sizing:content-box;
	-moz-box-sizing:content-box;
}

.google_maps img {
	max-width: none; /* needed for google map popup*/
}

/*
 * Header btn
 */

.header-btn {
	margin-top:5px;
}

/*
 * CKEDITOR
 */

.cke_top {
	background: rgba(248, 248, 248, 0.9) !important;
	background-image:none !important;
}

/*
 * BS Multiselect
 * TODO:
 */

.multiselect-container {
	position: absolute;
	list-style-type: none;
	margin: 0;
	padding: 0
}
.multiselect-container .input-group {
	margin: 5px
}
.multiselect-container > li {
	padding: 0
}
.multiselect-container > li > a.multiselect-all label {
	font-weight: bold
}
.multiselect-container > li > label.multiselect-group {
	margin: 0;
	padding: 3px 20px 3px 20px;
	height: 100%;
	font-weight: bold
}
.multiselect-container > li > a > label {
	margin: 0;
	height: 100%;
	cursor: pointer;
	font-weight: normal
}
.multiselect-container > li > a > label.radio, .multiselect-container > li > a > label.checkbox {
	margin: 0
}
.multiselect-container > li > a > label > input[type="checkbox"] {
	margin-bottom: 5px
}
.btn-group > .btn-group:nth-child(2)> .multiselect.btn {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px
}

/*
 * FORMS: INPUT WITH ICONS
 */


.input-group .icon-addon .form-control {
    border-radius: 0;
}

.icon-addon {
    position: relative;
    color: #555;
    display: block;
}

.icon-addon:after,
.icon-addon:before {
    display: table;
    content: " ";
}

.icon-addon:after {
    clear: both;
}

.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
    position: absolute;
    z-index: 2;
    left: 10px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px
}

.icon-addon.addon-lg .form-control {
    line-height: 1.33;
    height: 46px;
    font-size: 18px;
    padding: 10px 16px 10px 40px;
}

.input-group-lg .icon-addon.addon-lg + .input-group-btn>.btn {
	height:46px;
}

.icon-addon.addon-sm .form-control {
    height: 30px;
    padding: 5px 10px 5px 28px;
    font-size: 12px;
    line-height: 1.5;
}

.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
    font-size: 18px;
    margin-left: 0;
    left: 11px;
    top: 4px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
    padding-left: 30px;
    float: left;
    font-weight: normal;
}

.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
    margin-left: 0;
    font-size: 12px;
    left: 5px;
    top: -1px
}

.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
    color: #2580db;
}

.icon-addon input:disabled + .glyphicon,
.icon-addon select:disabled + .glyphicon,
.icon-addon input:disabled + .fa,
.icon-addon select:disabled + .fa {
	color:#ccc;
}
