.vis-item.main-schedule {
    color: white;
    background-color: #5fa9d6;
    border-color: #2c7ec5;
}

.vis-item.eventItem {
    border-color: unset;
    border-width: unset;
    background-color: unset;
}

.vis-item.vis-box.eventItem {
    border-style: unset;
    border-radius: 0;
}

.vis-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.50);
}

.vis-item.routing0 {
    background-color: #5fa9d6;
    border-color: #2c7ec5;
}
.vis-item.routing1 {
    background-color: #66bb6a;
    border-color: #4fa04f;
}
.vis-item.routing2 {
    background-color: #4dd0e1;
}
.vis-item.routing3 {
    background-color: #ffd54f;
}
.vis-item.routing4 {
    background-color: #8bc34a;
}
// .vis-item.routing5 {
//     background-color: #5fa9d6;
// }

.input-route-search {
    display: flex;
    align-items: center;
    left: 0px;
    margin-top: 10px;
    input {
        font-size:14px; border-radius: 4px !important;
    }
}
.vis-key-roaming {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4e2f5;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}

.vis-key-routing0 {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5fa9d6;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}
.vis-key-routing1 {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    background-color: #66bb6a;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}
.vis-key-routing2 {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    background-color: #4dd0e1;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}
.vis-key-routing3 {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    background-color: #ffd54f;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}
.vis-key-routing4 {
    margin: 10px;
    // width: 25px;
    // height: 25px;
    background-color: #8bc34a;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px black;
}

.animate-repeat {
  line-height:30px;
  list-style:none;
  box-sizing:border-box;
}

.animate-repeat.ng-move,
.animate-repeat.ng-enter,
.animate-repeat.ng-leave {
  transition:all linear 0.25s;
}

.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
  opacity:0;
  max-height:0;
}

.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
  opacity:1;
  max-height:30px;
}

div.vis-item.disable-resize > div.vis-drag-left{
    pointer-events: none;
}
div.vis-item.disable-resize > div.vis-drag-right{
    pointer-events: none;
}

div.vis-group-level-unknown-but-gte1 {
	border: none !important;
}
