/*.product-view {
	min-height: 380px;

	&> img {
		width:100%;
		height: auto;
		background: fade(#333,10%);
	}

	.price {
		position: absolute;
	    right: 10px;
	    margin-top: -35px;
	    padding: 14px 15px 14px 19px;
	    background: #ed1c24;
	    color: #FFF;
	    border-radius: 50%;
	    font-size: 25px;
	    font-weight: 300;

	    &>b {
	    	font-weight: 300;
			position: absolute;
			margin-top: 1px;
    		font-size: 16px;
			left: 10px;
	    }
	}

	h2 {
		font-size:18px;

	}

	p {
		font-size: 12px;
		margin-top:3px;
		width:90%;
		width: calc(~"100% - 10px");
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}*/

// Color variables (appears count calculates by raw css)
$color0: #dfe5e9; // Appears 4 times
$color1: #2f383d; // Appears 4 times
$color2: #21c2f8; // Appears 3 times
$color3: #ffffff; // Appears 3 times
$color4: #9aa7af; // Appears 2 times



.product-content {
	border: 1px solid $color0;
	margin-bottom: 20px;
	margin-top: 12px;
	background:$white;

	.carousel-control.left {
		margin-left:0px;
	}

	.product-image {
		background-color: $color3;
		display: block;
		min-height: 238px;
		overflow: hidden;
		position:relative;
	}
	.product-deatil {
		border-bottom: 1px solid $color0;
		padding-bottom: 17px;
		padding-left: 16px;
		padding-top: 16px;
		position: relative;
		background:$white;
		h5 {
			a {
				color: $color1;
				font-size: 15px;
				line-height: 19px;
				text-decoration: none;
				padding-left:0px;
				margin-left:0px;
				span {
					color: $color4;
					display: block;
					font-size: 13px;
				}
			}
		}
		p.price-container {
			span {
				line-height: 15px;
				line-height: 15px;
			}
		}
		span.tag1 {
			border-radius: 50%;
			color: $color3;
			font-size: 15px;
			height: 50px;
			padding: 13px 0px;
			position: absolute;
			right: 10px;
			text-align: center;
			top: 10px;
			width: 50px;
		}
		span.sale {
			background-color: $color2;
		}
		span.discount {
			background-color: #71e134;
		}
		span.hot {
			background-color: #fa9442;
		}
	}
	.description {
		font-size: 12.5px;
		line-height: 20px;
		padding: 10px 14px 16px 19px;
		background:$white;
	}
	.product-info {
		padding: 11px 19px 10px 20px;
		a.add-to-cart {
			color: $color1;
			font-size: 13px;
			padding-left:16px;
		}
	}
	name.a {
		padding:5px 10px;
		margin-left:16px;
	}
}

.product-info.smart-form .btn {
	padding: 6px 12px;
    margin-left: 12px;
    margin-top: -10px;
}

.product-entry {
	.product-deatil {
		border-bottom: 1px solid $color0;
		padding-bottom: 17px;
		padding-left: 16px;
		padding-top: 16px;
		position: relative;
		h5 {
			a {
				color: $color1;
				font-size: 15px;
				line-height: 19px;
				text-decoration: none;
				span {
					color: $color4;
					display: block;
					font-size: 13px;
				}
			}
		}
		p.price-container {
			span {
				line-height: 15px;
				line-height: 15px;
			}
		}
	}
}
.load-more-btn {
	background-color: $color2;
	border-bottom: 2px solid #037ca5;
	border-radius: 2px;
	border-top: 2px solid #00ccff;
	margin-top: 20px;
	padding: 9px 0px;
	width: 100%;
}
.product-block .product-deatil p.price-container span, 
.product-content .product-deatil p.price-container span, 
.product-entry .product-deatil p.price-container span, 
.shopping-items table tbody tr td p.price-container span, 
.shipping table tbody tr td p.price-container span  {
	color: $color2;
	font-family: 'Lato', sans-serif;
	font-size: 24px;
	line-height: 20px;
}

.product-info.smart-form .rating label {
	margin-top: 0px
}

.product-wrap .product-image span.tag2 {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 10px 0px;
    color: #fff;
    font-size: 11px;
    text-align: center;
}

.product-wrap .product-image span.sale {
    background-color: $brand-info;
}

.product-wrap .product-image span.hot {
    background-color: $brand-danger;
}

.shop-btn {
	position:relative;
	&>span {
	    background: $brand-danger;
	    display: inline-block;
	    font-size: 10px;
	    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
	    font-weight: bold;
	    border-radius: 50%;
	    padding: 2px 4px 3px 4px !important;
	    text-align: center;
	    line-height: normal;
	    width: 19px;
	    top: -7px;
	    left: -7px;
	}
}

.description-tabs {
	padding: 30px 0px 5px 0px !important;



	.tab-content {
		padding:10px 0px;
	}
}

.product-deatil {
	    padding: 30px 30px 50px 30px;

	    hr + .description-tabs {
	    	padding: 0px 0px 5px 0px !important;
	    }

	    .carousel-control.right,
	    .carousel-control.left {
	    	background:none !important;
	    }

	    .glyphicon {
	    	color: $brand-primary;
	    }

	    .product-image {
	    	border-right:none !important;
	    }

	    .name {
	    	margin-top:0px;
	    	margin-bottom:0px;
	    	small {
	    		display: block;
	    	}
	    	a { 
	    		margin-left:0px;
	    	}
	    	
	    }

	    .price-container {
    		font-size: 24px;
    		margin:0px;
    		font-weight: 300;

    		small {
    			font-size:12px;
    		}
	    }

	    .fa-2x {
	    	font-size:16px !important;

	    	&>h5 {
	    		font-size:12px;
	    		margin:0px;
	    	}
	    	&+ a,
	    	&+ a + a {
	    		font-size:13px;
	    	}

	    }
	    .certified {
	    	margin-top:10px;
	    }
	    .certified ul {
	    	padding-left:0px;
	    }
	    .certified ul li:not(first-child){
	    	margin-left:-3px;
	    }
		.certified ul li {

			display:inline-block;
			background-color: #f9f9f9;
			border: 1px solid #ccc;
			padding: 13px 19px;
		}
		.certified ul li:first-child {
			border-right:none;
		}
		.certified ul li a {
			text-align: left;
			font-size: 12px;
			color: #6d7a83;
			line-height: 16px;
			text-decoration: none;
		}
		.certified ul li a span {
			display:block;
			color: #21c2f8;
			font-size:13px;
			font-weight: 700;
			text-align: center;
		}
		 .message-text {
		 	width: calc(100% - #{70px});
		 	
		 }

}

@media only screen and (min-width: 1024px) {
	.product-content .product-image {
		border-right: 1px solid #dfe5e9;
		margin-right: -13px;
	}
	.product-content .product-info {
		position:relative;
	}
	.product-content .product-info,
	.product-content .description,
	.product-content .product-deatil{
		margin-left: -13px;
	}
	.product-deatil.product-content .product-info,
	.product-deatil.product-content .description,
	.product-deatil.product-content .description{
		margin-left: 0;
	}
}