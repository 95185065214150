$progressbarVerticalWidth: $line-height-computed;
$progressbarFontSize: $font-size-small;

// bootstrap-progressbar global styles
// -----------------------------------

.progress {
  position: relative;
}

.progress .progress-bar {
  position: absolute;
  overflow: hidden;
  line-height: $line-height-computed;
}

.progress .progressbar-back-text {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: $progressbarFontSize;
  line-height: $line-height-computed;
  text-align: center;
}

.progress .progressbar-front-text {
  display: block;
  width: 100%;
  font-size: $progressbarFontSize;
  line-height: $line-height-computed;
  text-align: center;
}

// bootstrap-progressbar horizontal styles
// ---------------------------------------

.progress.right .progress-bar {
  right: 0;
}

.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}

// bootstrap-progressbar vertical styles
// -------------------------------------

.progress.vertical {
  width: $progressbarVerticalWidth;
  height: 100%;
  float: left;
  margin-right: $progressbarVerticalWidth;
}

.progress.vertical.bottom {
  position: relative;
}

.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}

.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  @include transition(height .6s ease);
}

.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}

/*
 * PROGRESS BARS
 */

.progress-micro {
	height: $progressbar-micro !important;
	line-height:$progressbar-micro !important;
}

.progress-xs {
	height: $progressbar-xs !important;
	line-height:$progressbar-xs !important;
}

.progress-sm {
	height: $progressbar-sm !important;
	line-height:$progressbar-sm !important;	 	
}

.progress-lg {
	height: $progressbar-lg !important;
	line-height:$progressbar-lg !important;
}

.progress .progress-bar {
  position: absolute;
  overflow: hidden;
  line-height: 20px;
}

.progress .progressbar-back-text {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.progress .progressbar-front-text {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.progress.right .progress-bar {
  right: 0;
}

.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}

.progress.vertical {
  width: 25px;
  height: 100%;
  min-height:150px;
  margin-right: 20px;
  display:inline-block;
  margin-bottom:0px;
}

.progress.wide-bar {
	width: 40px;
}

.progress.vertical.bottom {
  position: relative;
}

.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}

.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
          transition: height 0.6s ease;
}

.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}

.progress {
	position: relative;
	margin-bottom: 20px;
	overflow: hidden;
	height: 22px;
	background: $gray-lighter;
	box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten( $gray-light, 29%) inset; 
	-webkit-box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten( $gray-light, 29%) inset;
	-moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px lighten( $gray-light, 29%) inset;
	
	border-radius:$progressbar-radius;
	-moz-border-radius:$progressbar-radius;
	-webkit-border-radius:$progressbar-radius;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 11px;
  color: $white;
  text-align: center;
  background-color: $blue;
  -webkit-box-shadow: inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.15);
          box-shadow: inset 0 -1px 0 rgba(red($black), green($black), blue($black), 0.15);
  font-weight:bold;
  text-shadow: 0 -1px 0 rgba(red($black), green($black), blue($black), 0.25);         
  background-image:url("#{$base-url}/pattern/overlay-pattern.png");      
}

.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
     -moz-animation: progress-bar-stripes 2s linear infinite;
      -ms-animation: progress-bar-stripes 2s linear infinite;
       -o-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-danger {
  background-color: $brand-danger;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-success {
  background-color: $brand-success;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: $brand-warning;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: $brand-info;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, rgba(red($white), green($white), blue($white), 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(red($white), green($white), blue($white), 0.15) 25%, transparent 25%, transparent 50%, rgba(red($white), green($white), blue($white), 0.15) 50%, rgba(red($white), green($white), blue($white), 0.15) 75%, transparent 75%, transparent);
}
	

.progress-info .bar, .progress .bar-info {
	background:  $brand-info;
}

.vertical-bars {
	padding:0;
	margin:0;
}

.vertical-bars:after {
	content: "";
	display: block;
	height: 0;
	clear: both;
}
.vertical-bars li {
	padding: 14px 0;
	width: 25%;
	display: block;
	float: left;
	text-align: center;
}
.vertical-bars li:first-child {
	border-left: none;
}
.vertical-bars > li > .progress.vertical:first-child {
	margin-left: auto;
}
.vertical-bars > li > .progress.vertical {
	margin: 0 auto;
	float: none;
}


/*
 * BAR HOLDER
 */
.bar-holder:first-child {
	border-top: none;
}
.bar-holder {
	padding: 18px 15px;
}
.bar-holder .progress {
	margin:0;
}

/* progress bar colors */

[data-progressbar-value] {
  margin-top:15px !important;
  overflow:visible;
  margin-bottom:0px;
}

.dataTable [data-progressbar-value] {
  min-width:70px;
}

[data-progressbar-value="100"]:before,
[data-progressbar-value="100"]:after,
[data-progressbar-value]:before,
[data-progressbar-value]:after {
  position: absolute;
  background: none;
  font-size: 11px;
  top: -12px;
}

[data-progressbar-value]:before {
  content: attr(data-progressbar-value);
  left:attr(data-progressbar-value) + px;
}
[data-progressbar-value]:after {
  content: "%";
  left:13px;
}
@for $i from 1 through 99{
  [data-progressbar-value="#{$i}"] > .progress-bar { width:$i * 1%; 
    @if $i < 10 {
      background:$brand-danger;
    }
    @if $i == 10 {
      background: lighten($brand-danger,10%)
    }
    @if  ($i <= 40 and $i > 10) {
      background: $brand-warning;
    }
    @if  ($i <= 60 and $i > 40) {
      background: $darken;
    }
    @if  ($i <= 80 and $i > 60) {
      background: $brand-primary;
    }
    @if  ($i <= 83 and $i > 80) {
      background: lighten($brand-success,15%);
    }
    @if  ($i <= 86 and $i > 83) {
      background: lighten($brand-success,14%);
    }
    @if  ($i <= 88 and $i > 86) {
      background: lighten($brand-success,12%)
    }
    @if  ($i <= 91 and $i > 88) {
      background: lighten($brand-success,10%)
    }
    @if  ($i == 92) {
      background: lighten($brand-success,9%)
    }
    @if  ($i == 93) {
      background: lighten($brand-success,8%)
    }
    @if  ($i == 94) {
      background: lighten($brand-success,7%)
    }
    @if  ($i == 95) {
      background: lighten($brand-success,6%)
    }
    @if  ($i == 96) {
      background: lighten($brand-success,5%)
    }
    @if  ($i == 97) {
      background: lighten($brand-success,4%)
    }
    @if  ($i == 98) {
      background: lighten($brand-success,3%)
    }
    @if  ($i == 99) {
      background: lighten($brand-success,2%)
    }
  }
}
[data-progressbar-value="100"] > .progress-bar { 
  width:100%; 
  background:$brand-success;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
  background-image:none !important;
}

[data-progressbar-value="100"]:before {
  content: attr(data-progressbar-value)'%';
  top: -12px;
  left:attr(data-progressbar-value) + px;
}
[data-progressbar-value="100"]:after {
  content: "complete";
  left:auto;
  right:0px;
  color:$brand-success;
}
