div.event-timeline .vis-item.vis-box {
    border-style: unset;
    border-radius: 0;
    border-color: unset;
    border-width: unset;
    background-color: unset;
}
div.event-timeline .vis-tooltip {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.50);
}

div.event-timeline-tooltip {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
}

div.event-timeline-tooltip .row {
    display: flex;
}

div.event-timeline-group{
    max-width: 4em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 1em;
}
div.event-timeline .vis-label .vis-inner {
    min-height: 4em;
}

tr.activeEvent {
    background-color: #3a9cef4f !important;
}

// .vis-time-axis .grid .vis-odd {
//     background: red;
// }
// .vis-time-axis .grid .vis-even {
//     background: red;
// }
