.counter-badge {
    display: inline-block;
    min-width: 10px;
    padding: 4.5px 7.5px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #9dc3de;
    border-radius: 10px;
}
