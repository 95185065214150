#routeDefinitionMap {
    height: 550px;
    width: 100%;
}

.new-route-form {
    width: 80%;
}
.new-summary-form {
    width: 100%;
}

.selected-waypoint {

}

input.invalidId{
    border-color: maroon !important;
}

.waypoint-small-label {
    padding: 0px !important;
}

.waypoint-circle {
    transition: fill 1s ease, stroke 1s ease;
}

.wp-del-button {
    background-color: rgba(255, 255, 255, 0.58);
    border-color: #c0c0c0;
    padding: 1px 4px;
}
.advanced-route-settings {
    transition: all 0.2s;
    cursor: pointer;
    position: absolute;
    top: 190px;
    left: 65px;
    z-index: 1000;
    border-radius: 5px;
    background-color: white;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    width: 34px;
    height: 34px;
    padding: 2px;
    overflow: hidden;

    i.advanced-icon {
        font-size: 1.5em;
        margin-left: -2px;
        margin-top: 1px;
        cursor: pointer;
    }
}

.advanced-route-settings-expanded {
    width: 250px;
    height: 200px;
    pointer-events: auto;
    cursor: auto;

    div.heading-area {
        position: fixed;
        background: white;
        width: 228px;
        z-index: 5000;
        margin-top: -2px;
    }
}
.advanced-route-settings-expanded div.heading-area legend h3 {
    opacity: 1 !important;
}

.advanced-route-settings-expanded-add {
    div.heading-area {
        transition: width 0.2s ease;
        width: 0px;
    }
}
.advanced-settings-body-area {
    padding-top: 2em;
    overflow: auto;
    max-height: 100%;
}
.advanced-settings-body-area::-webkit-scrollbar { width:12px; }
.advanced-settings-body-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.advanced-settings-body-area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.advanced-route-find {
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
    //make z-index 1 smaller than advanced-route-settings
    z-index: 1001 !important;
    overflow: hidden;
    position:absolute;
    left:65px;
    top:154px;
}

.advanced-route-find-expanded {
    width: 400px !important;
    height: 330px !important;
    pointer-events: auto !important;
    cursor: auto !important;
    // overflow: auto;
}

.advanced-settings-option {
    // align-items: center;
    padding: 5px;
    margin: 5px;
    border-style: groove;
    border-width: 1px;
}

.selected-waypoint-details {
    transition: opacity 0.25s ease, transform 0.3s ease;
    // transform: translate(-300px);
    padding: 10px;
    /* margin-left: 10px; */
    box-shadow: 0px 0px 9px 0px #555555;
    margin-left: 0.2em;
    margin-bottom: 2em;
}

.map-toolbar-button {
    transition: opacity 0.3s ease;
    cursor: pointer;
    z-index: 1000;
    border-radius: 5px;
    background-color: white;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    width: 34px;
    height: 34px;
    padding: 2px;
    overflow: hidden;
    opacity: 1;
}
.map-toolbar-button.ng-hide {
    opacity: 0;
}
.map-toolbar-button:hover {
    // border-radius: 10px;
}

.route-map-right-bar {
    position: absolute;
    z-index: 540;
    top: 0em;
}

.navigation-area-navcircles {
    transition: opacity 0.3s linear;
    position: absolute;
    // top: 33em;
    // left: 5em;
    bottom: -13px;
    opacity: 0;
}
.navigation-area-navcircles-show {
    opacity: 1;
}

.routes-navcircles {
    transition:  opacity 0.3s linear;
    opacity: 0;
}
.routes-navcircles-show {
    opacity: 1;
}
.navigation-area-navcircle {
    transition: transform 0.3s ease;
    position: relative;
    top: 0;
    z-index: 1;
    width: 15px;
    height: 15px;
    font-size: 1.5em;
    color: #fff;
    background: #a9b3b9;
    border: none;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.275);
    outline: none;
    cursor: pointer;
}
.selected-waypoint-navcircle {
    transform: scale(1.5);
}

// .waypoint-exit-right {
//     transform: translate(300px);
// }
//
// .waypoint-enter-left {
//     transform: translate(0px);
// }

#movingTooltip {
    display: none;
    position: absolute;
    background: #666;
    color: white;
    opacity: 0.5;
    padding: 10px;
    border: 1px dashed #999;
    font-family: sans-serif;
    font-size: 12px;
    height: 20px;
    line-height: 0px;
    z-index: 1000;
}

.distance-line {
    transition: opacity 0.3s ease;
    stroke: rgb(51, 136, 255);
    stroke-opacity: 1;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 5, 10;
    fill: none;
}

.distance-line-hide {
    opacity: 0;
}

.distance-line-tooltip {
    transition: opacity 0.3s ease;
    padding: 0px;
    background-color: rgba(33, 150, 243, 0.32);
    font-family: “Helvetica Neue”, Arial, sans-serif;
}

.distance-line-tooltip-hide {
    opacity: 0 !important;
}

.waypoint-attributes-container {
    position: relative;
    width: 300px;
}
.waypoint-attributes-item {
    transition: opacity 0.3s ease;
    display: none;
}
.waypoint-attributes-item.waypoint-fading-out-add {
    position: absolute;
    top: 0px;
    width: 100%;
    display: block;
    opacity: 1;
}
.waypoint-attributes-item.waypoint-fading-out-add-active {
    opacity: 0;
}
.waypoint-attributes-item.waypoint-fading-in-add {
    position: absolute;
    top: 0px;
    width: 100%;
    display: block;
    opacity: 0
}
.waypoint-attributes-item.waypoint-fading-in-add-active {
    opacity: 1;
}
.waypoint-attributes-item-show {
    display: block;
    // opacity: 1;
}

.waypoints-summary-area {
    // font: normal medium/1.4 sans-serif;
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th, td {
        padding: 0.25rem;
        text-align: left;
        border: 1px solid #ccc;
    }
    tbody tr:nth-child(odd) {
        background: #eee;
    }
}

.ui-select-site-open {
    min-height: 300px;
}

// Used at step 2. We click on these shapes to add a waypoint to route
.waypoint-route-clickable-shape {
    transition: all 0.5s ease;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px rgba(0, 61, 255, 0.35) solid;
    margin-top: -11px;
    margin-left: -11px;
}
.waypoint-route-clickable-shape:hover {
    border: 15px rgba(0, 61, 255, 0.35) solid;
    height: 35px;
    width: 35px;
    margin-top: -13px;
    margin-left: -13px;
}

// Used at step 1 to visually indicate that a waypoint is in 'edit' mode
.waypoint-route-show-active {
    transition: all 0.5s ease;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border-radius: 50%;
    border-top: 3px solid #8fc9d2;
    border-bottom: 3px solid #a6b3bc;
    margin-top: -11px;
    margin-left: -11px;
    animation: spin-right 3s linear infinite;
}
//this is the class of the divIcon which contains another div with the class above
.indicate-active-waypoint {
    pointer-events: none !important;
}
@-webkit-keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.waypoint-div-icon {
    background: transparent !important;
    border: none !important;
}

.ng-invalid{
    border-color:indianred !important;
}
.ng-invalid.ui-select-multiple > ul{
    border-color:indianred !important;
}
