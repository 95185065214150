.fa-stack .cornered-lr {
	position: absolute;
	bottom: 0px !important;
	text-align: right;	
	line-height: 1em;
	text-shadow: 1px 1px 1px #000;
}

/* other corners available too */
.fa-stack .cornered-ll {
	position: absolute;
	bottom: 0px !important;
	text-align: left;	
	line-height: 1em;
	text-shadow: 1px 1px 1px #000;
}

.fa-stack .cornered-tr {
	position: absolute;
	top: 0px !important;
	text-align: right;	
	line-height: 1em;
	text-shadow: 1px 1px 1px #000;
}

.fa-stack .cornered-tl {
	position: absolute;
	top: 0px !important;
	text-align: left;	
	line-height: 1em;
	text-shadow: 1px 1px 1px #000;
}
