div.ptt-panel {
	position:relative;
	width:420px;
	left:20px;
	display: flex;
	flex-direction: column;
	max-height: 70vh;
	height: unset;
}

div.ptt-panel-header {
	display:flex;
	justify-content:space-around;
	align-items:center;
	height:4em;
	width:100%;
	background-color:#f5f5f5;
	border:1px solid #ddd;

}

.ajax-dropdown:after{
	left:98%
}
.ajax-dropdown:before{
	left:98%
}
.ajax-dropdown {
	-webkit-animation-name: unset !important;
	-moz-animation-name: unset !important;
	-o-animation-name: unset !important;
	animation-name: unset !important;
	-webkit-animation-duration: unset !important;
	-moz-animation-duration: unset !important;
	-o-animation-duration: unset !important;
	animation-duration: unset !important;
	-webkit-animation-fill-mode: unset !important;
	-moz-animation-fill-mode: unset !important;
	-o-animation-fill-mode: unset !important;
	animation-fill-mode: unset !important;
}

button.call-button {
	width:3em;
	height:3em;
}

button.call-button > i{
	width:1em;
	height:1em;
}


div.ptt-panel-footer {

}

li.active-ptt-item {

}

div.ptt-panel-body {
	max-height:70vh;
	overflow:scroll;
}

.ajax-notifications {
	height: unset !important;
	border-bottom: 1px solid #999;
	border-top: 1px solid #999;
}

.group-peers-animation {
	transition: all linear 0.5s;
}
.group-peers-animation.ng-hide {
	opacity:0;

}

.notification-body .panel-group{
	margin-bottom:0px !important;
}

.custom-btn-header {
	display: inline-block;
	color: #C5C6CA;
	height: 30px;
	line-height: 24px;
	min-width: 30px;
	padding: 13px 3px 0px 0px;
	text-align: center;
	font-size: 21px;
}

.custom-btn-header:hover {
	color:#ffffff;
}

.custom-btn-header .stack-icon {
	position: relative;
    left: 7px;
    top: -9px;
}

.offset-call-dropdown {
	left:35px !important;

}
