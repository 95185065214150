
#Events {
    background:rgba(101,113,101,0.5); //because it's an accordion
}

.sub-header-tab {
    width: 100%;
    position: absolute;
    overflow: auto;

    .rg-right {
        margin-right: 1px;
    }
}

.counter-badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #9dc3de;
    border-radius: 10px;
}
.blurred {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}
.blurred:hover {
    opacity: 1;
    transform: scale(1.1);
}
.activeAccordion {
    transition: box-shadow 0.2s ease-in-out;
    //so when the activeAccordionEl gets applied, we have the transition ready
}
.activeAccordionEl {
    box-shadow: inset 0px -1px 1px 3px #d4d4d4;
}
.active-photo {
    transition: box-shadow 0.4s ease-in-out;
}
.active-photo-el {
    box-shadow: 0px 0px 20px 10px #d4d4d4;
}
.actioned-event-leave {
    animation: slide-out 0.5s ease-in-out forwards;
    transform-origin: center center;
}
.event-viewed {
    font-weight: normal;
}
.showing-history {
    opacity: 1;
    transform: scale(1.1);

}

/* Large */
.photoContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;

}
/* Medium screens */
@media all and (max-width: 1200px) {
  .photoContent {
    /* When on medium sized screens, we center it by evenly distributing empty space around items */
    flex-flow: column wrap;
    justify-content: space-around;
  }
  #Photos {
      width: 30%;
  }
  .maps-header-content {
      flex-direction: row;
  }
  .map-filter-options {
      flex-direction: column;
  }
}

/* Small screens */
@media all and (max-width: 500px) {
  .photoContent {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
  #Photos {
      width:20%;
  }
  .maps-header-content {
      flex-direction: row;
  }
  .map-filter-options {
      flex-direction: column;
  }
}

#Videos {
    overflow: auto;
    position: absolute;
    width: 34%;
}
#Maps {
    position: absolute;

    // overflow: auto; //dropping marker
    // background-color: aliceblue;
    pointer-events: none;
    overflow: auto;
    // width:100%;
}
.map-filter-options {

}
.auto-pointer {
    pointer-events: auto;
}
.swivel-marker {
  transition: filter 1s ease-in-out;

}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}


.wiggle:hover {
  animation: none;
}


.unit-online {
    box-shadow: 0px 0px 13px 1px #b1f992;
    .icon-area {
        color: #72bb53;
    }
}
.unit-offline {
    // color:#464545;
    box-shadow: px 0px 3px 0px #464545;
    background-color:antiquewhite;
    .icon-area {
        color: #6C2268;
    }
}
.unit-detail-item {
    // transition: all 0.3s;
}
.selected-unit {
    border : solid 1px #bfbfbf;
    // transform: scale(1.03);
}

.myTickBox input[type="checkbox"] {
    display: none;
}

.myTickBox input[type="checkbox"] + .btn-group label {
    background-color: white;
    padding: 7px 7px;
    left: 5px;
    // border-radius: 0px;
    // width: 10px;
    // height: 10px;
}

.myTickBox input[type="checkbox"] + .btn-group > label span {
    width: 10px;
    height: 10px;
}
.myTickBox input.ng-not-empty + .btn-group > label span {
    color: red;
}

.count-badge {
	background-color: tomato;
    border-radius: 1em;
    box-sizing: border-box;
    color: white;
    height: 1.5em;
    line-height: 1;
    max-width: 5em;
    min-width: 1.5em;
    overflow: hidden;
    padding: .25em;
    text-overflow: ellipsis;
    /* transform: scale(.25); */
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
}

.disabled-icon-button {
	cursor: unset;
	color: #999;
}
