.modal-xlg {
    width: 90%;
}

.bluetoothBeaconMarker {
  text-align: center;
  /* Horizontally center the text (icon) */
  line-height: 20px;
  /* Vertically center the text (icon) */
}

.validBeacon {
  color: #3D81FC;
  border-color: #3D81FC;
}

.invalidBeacon {
  color: red;
  border-color: red;
}

.selectedMarker {
  border: 3px dashed;
  margin: -3px;
}

form.new-site-form {
    // width: 750px;
    width: 100%;
}



// .newsite-form-input {
//     border-radius: 5px !important;
// }
.address-panel{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}
.address-panel:hover{
    box-shadow: -5px 5px 8px 2px rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.contains-error {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}

#siteMapWizard {
    width: 100%;
    height: 500px;
}

.new-site-map-tooltip {
    z-index: 1050;
}

.should-slide {
    transition: margin 1s;
    -moz-transition: margin 1s; /* Firefox 4 */
    -webkit-transition: margin 1s; /* Safari and Chrome */
    -o-transition: margin 1s; /* Opera */
}

.should-slide-fast {
    opacity: 0;

    transition: margin 0.4s, opacity 0.4s;
    -moz-transition: margin 0.4s, opacity 0.4s; /* Firefox 4 */
    -webkit-transition: margin 0.4s, opacity 0.4s; /* Safari and Chrome */
    -o-transition: margin 0.4s, opacity 0.4s; /* Opera */
}

.choosing-colors {
    filter: brightness(20%);
}

.choosing-color {
    opacity: 1;
    margin-top: 1.6em;
}

.advanced-site-settings {
    transition: all 0.2s;
    cursor: pointer;
    position: absolute;
    top: 225px;
    left: 10px;
    z-index: 1000;
    border-radius: 5px;
    background-color: white;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    width: 34px;
    height: 34px;
    padding: 2px;
    overflow: hidden;

    i.advanced-icon {
        font-size: 1.5em;
        margin-left: -2px;
        margin-top: 1px;
        cursor: pointer;
    }
}

.advanced-site-settings-expanded {
    width: 250px;
    height: 200px;
    pointer-events: auto;
    cursor: auto;

    div.heading-area {
        position: fixed;
        background: white;
        width: 228px;
        z-index: 5000;
        margin-top: -2px;
    }
}
.advanced-site-settings-expanded div.heading-area legend h3 {
    opacity: 1 !important;
}

.advanced-site-settings-expanded-add {
    div.heading-area {
        transition: width 0.2s ease;
        width: 0px;
    }
}
.advanced-site-settings-body-area {
    padding-top: 2em;
    overflow: auto;
    max-height: 100%;
}
.advanced-site-settings-body-area::-webkit-scrollbar { width:12px; }
.advanced-site-settings-body-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.advanced-site-settings-body-area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.drawing-polygon {
    margin-top: 0em !important;
    margin-right: 0em !important;
}

// .advanced-settings-container {
//     transition: all 0.2s;
//     cursor: pointer;
//     position: absolute;
//     top: 180px;
//     left: 10px;
//     z-index: 1000;
//     border-radius: 5px;
//     background-color: white;
//     border: 2px solid rgba(0,0,0,0.2);
//     background-clip: padding-box;
//     width: 34px;
//     height: 30px;
//     padding: 2px;
//     overflow: hidden;
//
//     i.advanced-icon {
//         position: absolute;
//         font-size: 1.6em;
//         top: 3px;
//         margin-left: 1px;
//     }
//
//     i.move-advanced-icon {
//         // pointer-events: auto;
//         // right: 3px;
//         // cursor: pointer;
//     }
// }
// .advanced-settings-container-expanded {
//     width: 400px;
//     height: 200px;
//     pointer-events: auto;
//     cursor: auto;
// }

.leaflet-tooltip-class {
    background: rgba(235, 235, 235, 0.81);
    background-clip: padding-box;
    border-color: #777;
    border-color: rgba(0 , 0, 0, 0.25);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: #111;
    display: block;
    font-weight: bold;
    padding: 1px 6px;
    position: absolute;

}
.leaflet-note-class {
    opacity: 0;
}

.site-contact-summary {
    border: 1px solid rgba(66, 133, 244, 0.36);
    border-style: dashed;
}

/**
 * This class gets applied to all siteDetail2 labels we create
 */
.site-data-label {
    transition: font-size 0.05s, opacity 0.05s;
    background-color: rgba(255,255,255,0);
    padding: 2px;
    border: none;
    box-shadow: none;
}
.detail2-smaller {
    font-size: 0.8em;
    opacity: 0.8;
}
.detail2-smaller2 {
    font-size: 0.6em;
    opacity: 0.6;
}
.detail2-bigger {
    font-size: 1.2em;
    opacity: 1;
}
.detail2-bigger2 {
    font-size: 1.6em;
    opacity: 1;
}


.col-op-1 {
    background: #f44336 !important;
}
.col-op-2 {
    background: #2196f3 !important;
}
.col-op-3 {
    background: #ff9800 !important;
}
.col-op-4 {
    background: #3f51b5 !important;
}
.col-op-5 {
    background: #8bc34a !important;
}
.col-op-6 {
    background: #607d8b !important;
}
