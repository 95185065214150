div.vertical-line{
      width: 0px; /* Use only border style */
      height: 100%;
      float: left;
      border: 1px inset; /* This is default border style for <hr> tag */
    }

.invalidInput{
    border: 1px solid maroon  !important;
}

.unit-counter-button {
    position: relative;
    top: 0;
    z-index: 1;
    width: 70px;
    height: 70px;
    font-size: 1.5em;
    color: #fff;
    background: #2C98DE;
    border: none;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0,0,0,.275);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 5px;
}

.activity-type-table {
    table {
        border-collapse: collapse;
    }

    td, th {
        border: 1px solid rgb(207, 207, 207);
        padding: 1rem;
        // text-align: center;
    }
}

.inclusion-exclusion-table {
    width: 100%;
    table {
        border-collapse: collapse;
    }

    td, th {
        border: 1px solid rgb(207, 207, 207);
        padding: 0.5rem;
        text-align: center;
    }
}

.number-spinner {
    width: 180px;
}



.onoffswitch1 {
    position: relative; width: 100px; //was 180px //then 136px
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.onoffswitch1-checkbox {
    display: none;
}

.onoffswitch1-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 30px;
}

.onoffswitch1-inner {
    display: block; width: 200%; margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s; -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s; transition: margin 0.3s ease-in 0s;
}

.onoffswitch1-inner:before, .onoffswitch1-inner:after {
    display: block; float: left; width: 50%;
    height: 25px; //was 30px
    padding: 0;
    line-height: 26px; //was 30px
    font-size: 13px; color: white; font-family: Trebuchet, Arial, sans-serif;
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
    border-radius: 30px;
    box-shadow: 0px 15px 0px rgba(0,0,0,0.08) inset;
}

.onoffswitch1-inner:before {
    content: "Include";
    padding-left: 6px; //was 10px
    background-color: #4387bf; color: #FFFFFF;
    border-radius: 30px 0 0 30px;
}

.onoffswitch1-inner:after {
    content: "Exclude";
    padding-right: 5px; //was 10px
    background-color: #EEEEEE; color: #999999;
    text-align: right;
    border-radius: 0 30px 30px 0;
}

.onoffswitch1-switch {
    display: block;
    width: 45px; //was 90px then 66px
    margin: 0px;
    background: #FFFFFF;
    border: 2px solid #999999; border-radius: 30px;
    position: absolute; top: 0; bottom: 5px; right: 55px; //right:90px then 70px;
    -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s;
    background-image: -moz-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
    background-image: -webkit-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
    background-image: -o-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
    background-image: linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
    box-shadow: 0 1px 1px white inset;
}

.onoffswitch1-checkbox:checked + .onoffswitch1-label .onoffswitch1-inner {
    margin-left: 0;
}

.onoffswitch1-checkbox:checked + .onoffswitch1-label .onoffswitch1-switch {
    right: 0px;
}

.disabled-switch>label::before {
    background: red;
}

.tooltip-big-z {
    z-index: 1050;
}

td.uib-increment > a{
    padding: unset;
}
td.uib-decrement > a{
    padding: unset;
}
