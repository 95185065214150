.siteListContainer {
    // width: 40%;

    .rg-bottom {
        margin-bottom: 0px;
    }
    .rg-right {
        margin-right: 0px;
    }
}

// @import './site/site-list/site-list';
// @import './site/site-detail/site-detail.component';
