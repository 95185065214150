$icon-font-path: '/assets/fonts/bootstrap/';
$fa-font-path: '/assets/fonts/font-awesome/';
$nb-font-path: '/assets/fonts/new-brilliant';
$base-url: '/assets/images';
@import '../SmartAdmin/scss/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import '../SmartAdmin/scss/custom';
//@import '~font-awesome/scss/font-awesome';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/v4-shims';
@import '~ng-table/src/styles/ng-table.scss';
@import '~ui-select/dist/select.css';
@import '~angular-ui-grid/ui-grid.css';
@import '../SmartAdmin/scss/smartadmin-production-plugins.scss';
@import '../SmartAdmin/scss/smartadmin-production.scss';
@import '../SmartAdmin/scss/smartadmin-skins.scss';
@import '../SmartAdmin/scss/smartadmin-angular.scss';
@import '~c3/c3.css';

@import '~leaflet/dist/leaflet.css';
// @import '~lrm-mapzen/dist/lrm-mapzen.css';
// @import '~mapbox.js/theme/style.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~leaflet-draw/dist/leaflet.draw-src.css';
@import '~leaflet-draw/dist/leaflet.draw.css';
// @import '~leaflet-geocoder-mapzen/dist/leaflet-geocoder-mapzen.min.css';
@import '~leaflet-control-geocoder/dist/Control.Geocoder.css';
@import '~leaflet-basemaps/L.Control.Basemaps.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet-toolbar/dist/leaflet.toolbar.css';
@import '~angular-carousel/dist/angular-carousel.min.css';
@import '~angular-gridster/dist/angular-gridster.css';

@import '~angular-confirm1/dist/angular-confirm.min.css';
@import '~angular-toastr/dist/angular-toastr.min.css';
@import '~angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.min.css';
@import '~angular-xeditable/dist/css/xeditable.min.css';
@import '~v-tabs/dist/v-tabs.min.css';
@import '~clockpicker/dist/bootstrap-clockpicker.min.css';
//@import '~fullcalendar/main.css';
@import '~angularjs-color-picker/dist/angularjs-color-picker.min.css';
@import '~angularjs-color-picker/dist/themes/angularjs-color-picker-bootstrap.min.css';
@import '~vis-timeline/styles/vis-timeline-graph2d.min.css';
@import '~angular-moment-picker/dist/angular-moment-picker.min.css';

@import '~angular/angular-csp.css';
@import '~cookieconsent/build/cookieconsent.min.css';
// @import url(http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,900|Roboto:300,500,700,900);

/**
 * App-wide Styles
 */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.hidden-menu #left-panel {
    //this is done to ensure that when our left-panel is collapsed and we move our mouse
    //over it, it still has a z-index higher than our eventDetailContainer, eventCarouselContainer.
    z-index: 3000;
}
.select2-search-choice-close:before {
  color:#fff;
  content:"\f057"
}

.smart-style-2 nav ul li a:hover span.customListHover{
    background-color: #202427 !important;
}

.select2-search-choice-close{
  display: block;
  min-width: 21px;
  min-height: 20px;
  position: absolute;
  right: 3px;
  top: 3px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  text-decoration: none!important;
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection__choice__remove, .select2-container-multi .select2-search-choice-close {
  font-family: 'Font Awesome 6 Pro';
}
@font-face {
  font-family: 'NewBrilliant';
  src: url('#{$nb-font-path}/new-brilliant.ttf') format('truetype');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

.table-responsive {
    overflow-x: unset;
}

[uib-tooltip-popup] {
	z-index:10000;
}

.ng-table th.sortable .sort-indicator{
	display: flex;
	align-items: center;
	justify-content: center;
}

.leaflet-control-geocoder-icon::before {
	content: "\f002";
	font-family: "FontAwesome";
}
@import '~xterm/css/xterm.css';

// Component styles are injected through gulp
/* inject:scss */
@import 'account/login/login';
@import 'admin/admin';
@import 'configure/configure';
@import 'contacts/contacts';
@import 'dashboard/dashboard';
@import 'dashboard/mainheadings/mainheadings.component';
@import 'dashboard/selected-event/event-photos/event-photos.component';
@import 'dashboard/selected-event/selected-event.component';
@import 'dashboard/subtabs/subtabs.component';
@import 'dashboard/tabcontent/tabcontent.component';
@import 'events/events';
@import 'fieldReports/templates/new-template/new-template.modal';
@import 'fieldReports/templates/templatess';
@import 'groups/group-list/group-list';
@import 'groups/group-list/new-group/new-group';
@import 'groups/group';
@import 'main/main';
@import 'photoAnalytics/faceCollection/faceCollection';
@import 'photoAnalytics/imageCollection/imageCollection';
@import 'photoAnalytics/plateCollection/plateCollection';
@import 'privGroups/privGroups';
@import 'reports/reports';
@import 'routes/route-list/new-route/new-route';
@import 'routes/route-list/route-detail/route-detail';
@import 'routes/route-list/route-list';
@import 'routes/route';
@import 'scheduling/add-schedule/scheduling.modal';
@import 'scheduling/schedule-list/schedule-list';
@import 'scheduling/scheduling';
@import 'scheduling/ui-calendar/ui-calendar';
@import 'scheduling/vis-schedule-timeline/vis-schedule-timeline';
@import 'settings/add-account/account.modal';
@import 'settings/app-version/app-version';
@import 'settings/custom-events/custom-event-list';
@import 'settings/settings';
@import 'shared/directives/directives';
@import 'shared/directives/grow-button/grow-button.directive';
@import 'shared/directives/material-button/material-button';
@import 'shared/directives/myMap/my-map.directive';
@import 'shared/directives/phone-input/phone-input';
@import 'shared/directives/resizable/resizable.directive';
@import 'shared/directives/wizard/app-wizard.directive';
@import 'sites/site-detail/site-detail.component';
@import 'sites/site-list/new-site/new-site';
@import 'sites/site-list/site-list';
@import 'sites/site';
@import 'sops/custom-event-type/custom-event-type';
@import 'sops/sop-list/manage-sop-tags/manage-sop-tags';
@import 'sops/sop-list/new-sop/new-sop';
@import 'sops/sop-list/sop-list';
@import 'sops/sops';
@import 'units/units-list/minimap-modal/minimap-modal';
@import 'units/units';
@import '../components/audio-element/audio-element';
@import '../components/consent/consent';
@import '../components/dms-terminal/dms-terminal';
@import '../components/footer/footer';
@import '../components/header/header';
@import '../components/live-stream/container/live-stream-container';
@import '../components/live-stream/panel/live-stream-panel';
@import '../components/password-strength-meter/password-strength-meter';
@import '../components/ptt-panel/ptt-panel';
@import '../components/toggle-switch/toggle-switch';
@import '../components/totp/totp';
@import '../components/vis-events-timeline/vis-events-timeline';
/* endinject */
