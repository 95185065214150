// http://cssdeck.com/labs/elvp76rk
// I'm thinking of implementing a 'sign-up' animation using the above
.fixed-ribbon #extr-page #content {
    padding-top: 10px;
}
.fixed-header #extr-page #header {
    position: relative;
}
.validate-class.state-error {
  opacity: 1;
  padding: 2px;
  background: white;
  position: relative;
  color: black;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
.validate-class {
  line-height: 20px;
  opacity: 1;
  padding: 0px;
  background: white;
  position: relative;
  color: black;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
.errorbox {
  top: 50px;
}
//The class that contains the background image
// .img-container {
//     -webkit-filter: blur(10px);
//     -moz-filter: blur(10px);
//     -o-filter: blur(10px);
//     -ms-filter: blur(10px);
//     filter: blur(10px);
//     background-image: url(/assets/images/login/background.jpg);
//     width: 100%;
//     height: 100%;
//     position: absolute;
// }
// .wrap
// {
//     width: 100%;
//     height: 100%;
//     min-height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 99;
// }
//
.nb-title{
    font-family:'NewBrilliant'
}
//
p.form-title
{
    font-family: 'NewBrilliant';
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;
    margin-top: 5%;
    text-transform: uppercase;
    letter-spacing: 4px;
}
// form {
//     width: 250px;
//     margin: 0 auto;
// }
// form.login input[type="email"], form.login input[type="password"] {
//     width: 100%;
//     margin: 0;
//     padding: 5px 10px;
//     // background: 0;
//     border: 0;
//     border-bottom: 1px solid #FFFFFF;
//     outline: 0;
//     font-style: italic;
//     font-size: 12px;
//     font-weight: 400;
//     letter-spacing: 1px;
//     margin-bottom: 5px;
//     // color: #FFFFFF;
//     outline: 0;
// }
// form.login input[type="submit"] {
//     width: 100%;
//     font-size: 14px;
//     text-transform: uppercase;
//     font-weight: 500;
//     margin-top: 16px;
//     outline: 0;
//     cursor: pointer;
//     letter-spacing: 1px;
//     background-color: rgba(219, 66, 59, 0.68);
//     border-color: rgba(255, 255, 255, 0);
// }
// form.login input[type="submit"]:hover {
//     transition: background-color 0.5s ease;
//     background-color: rgb(219, 66, 59);
//     border-color: rgba(219, 66, 59, 0.5);
// }
// form.login .remember-forgot {
//     float: left;
//     width: 100%;
//     margin: 10px 0 0 0;
// }
// form.login .forgot-pass-content {
//     min-height: 20px;
//     margin-top: 10px;
//     margin-bottom: 10px;
// }
// form.login label, form.login a {
//     font-size: 12px;
//     font-weight: 400;
//     // color: #FFFFFF;
// }
// form.login a {
//     transition: color 0.5s ease;
// }
// form.login a:hover {
//     color: #2ecc71;
// }
// .pr-wrap {
//     width: 100%;
//     height: 100%;
//     min-height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 999;
//     display: none;
// }
// .show-pass-reset {
//     display: block !important;
// }
// .pass-reset {
//     margin: 0 auto;
//     width: 250px;
//     position: relative;
//     margin-top: 22%;
//     z-index: 999;
//     background: #FFFFFF;
//     padding: 20px 15px;
// }
// .pass-reset label {
//     font-size: 12px;
//     font-weight: 400;
//     margin-bottom: 15px;
// }
// .pass-reset input[type="email"] {
//     width: 100%;
//     margin: 5px 0 0 0;
//     padding: 5px 10px;
//     background: 0;
//     border: 0;
//     border-bottom: 1px solid #000000;
//     outline: 0;
//     font-style: italic;
//     font-size: 12px;
//     font-weight: 400;
//     letter-spacing: 1px;
//     margin-bottom: 5px;
//     color: #000000;
//     outline: 0;
// }
// .pass-reset input[type="submit"] {
//     width: 100%;
//     border: 0;
//     font-size: 14px;
//     text-transform: uppercase;
//     font-weight: 500;
//     margin-top: 10px;
//     outline: 0;
//     cursor: pointer;
//     letter-spacing: 1px;
// }
// // To make the submit-button have a transition
// .pass-reset input[type="submit"]:hover {
//     transition: background-color 0.4s ease;
// }
// .posted-by {
//     position: absolute;
//     bottom: 26px;
//     margin: 0 auto;
//     color: #FFF;
//     background-color: rgba(0, 0, 0, 0.66);
//     padding: 10px;
//     left: 45%;
// }
//
// .slide-out {
//     animation: slide-out 1.5s ease-in-out forwards;
//     transform-origin: center center;
// }
// @keyframes slide-out {
//     0% {
//         transform: translateX(0%)
//     }
//     100% {
//         transform: translateX(300%)
//     }
// }
