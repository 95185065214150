
.live-stream-panel {
	display: flex;
	flex-direction: column;
	height: 100%;
	//border-radius: 3.5px;
	padding: 3.5px;
	background-color: #2c3742;
	z-index:1028;
	//top:200px;
	//left:400px;
	width:300px;
	height:200px;
}

.live-stream-panel.minimized {
	max-height:0px;
}
.live-stream-panel.enter {
	z-index:1029;
}

.live-stream-panel.minimized .box-header {
	display: flex !important;
}

//.live-stream-panel:hover .box-header {
	//display: flex;
//}
.box-header {
	top:-39px;
	left:0px;
	z-index:1029;
	color: white;
    background-color: #2c3742;
	border: 3px solid #2c3742;
    padding: 0 10px;
    cursor: move;
    align-items: center;
    display: flex;
	justify-content: space-between;
	height:3em;
	position:absolute;
	width: 100%;
}

.grid-title{
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-grow: 1;
}

.ellide-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.live-stream-panel:hover .box-footer {
	display: flex;
}

.box-footer {
	z-index:1029;
	color: white;
    background-color: #2c3742;
    padding: 0 30px 0 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    display: none;
    width: 100%;
	height:1em;
	position:absolute;
	bottom:3.5px;
	width: calc(100% - 7px)
}

.box-content {
	width:100%;
	max-height: 100%;
	flex-grow:1;
    background-color: black;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.box-header-btns {
    cursor: pointer;
	display: flex;
}





.fit {
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: auto;
}

.fit:-webkit-full-screen {
    padding:0px;
}

.fit:full-screen {
    padding:0px;
}

.testIm2 {
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.live-stream-panel {
	.rg-bottom {
		margin-bottom:-14px;
	}
	.rg-top {
		margin-bottom:-14px;
	}
}

