@import './new-site/new-site';

.toggled-coordinates {
    box-shadow: 0px 0px 2px 0px #4387bf;
}

.location-table {
    table {
        border-collapse: collapse;
    }

    td, th {
        border: 1px solid rgba(67, 135, 191, 0.29);
        padding: 0.5rem;
        text-align: center;
    }
}
.site-list-main-view {
    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-track {
        // background-color: #ebebeb;
        // background-color: #4285f4;
        background-color: white;
        // -webkit-border-radius: 10px;
        // border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        // -webkit-border-radius: 10px;
        // border-radius: 10px;
        background: #4285f4;
        // background: #6d6d6d;
    }

}
.site-list-content {
    // overflow-y: scroll;
    // max-height: 36em;

}
.site-list-item {
    box-shadow: 0px 0px 1px 0px #4285f4;
    // margin-left: -3px;
    margin: 15px;
    background-color: rgb(255,255,255);
    padding: 10px;
    width: 380px;
    transition: transform 0.4s ease, box-shadow 0.6s ease;
}

.site-list-item:hover {
    transform: scale(1.04);
    box-shadow: 0px 0px 5px 1px #4285f4;
}

.site-list-item-buttons {
    width: 50px;
    color: grey;
    transition: all 0.5s ease;
}

.item-button {
    font-size: 17px;
    color: #4387bf;
}
.active-site-list-item {
    // border-left: 1px solid lightblue;
    // transform: translate(10px);
    box-shadow: 0px 0px 5px 1px #4285f4;
}
