@import './subtabs/subtabs.component';
@import './tabcontent/tabcontent.component';
@import './selected-event/selected-event.component';
@import './mainheadings/mainheadings.component';

// @import '~angular-resizable/angular-resizable.min.css';

//This is to fill the available height without requiring the parent div to change
.dashboard-main-content {
	position:absolute !important;
	display:flex !important;
	flex-direction:column !important;
	height: calc(100vh - 105px) !important;
	padding:unset !important;
}


#content {
    height: 100%;
    width: 100%
}
#map {
    height: 100%; //TODO: The map must assume 100% of the height available to it. Flexbox perhaps?
    width: 100%;
}
.flexContainerColumn {
    display: flex;
    flex-flow: column wrap;
}
.flexContainerRow {
    display: flex;
    flex-flow: row wrap;
}
.justifiedAround {
    justify-content: space-around;
}
.justifiedBetween {
    justify-content: space-between;
}
.justifiedCenter {
    justify-content: center;
}
.justifiedStart {
    justify-content: flex-start;
}
.justifiedEnd {
    justify-content: flex-end;
}
.alignedCenter {
    align-items: center;
}
.flexBoxItem {
    margin: 5px;
}
.flexGrow {
    flex-grow: 1;
}
.cursorPointer {
    cursor: pointer;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
    //This animation has been obtained from:
    //http://bl.ocks.org/mourner/4284949
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
}
.mapContainer {
    // margin: 0px;
    // border: 0px;
    // padding: 0px;
    // height: 100%;
    // position: relative;
}
.flex-container {
    display: flex;
    flex-flow: column, wrap;
    justify-content: space-around;
}
.flex-container2 {
    display: flex;
    flex-flow: row, wrap;
    justify-content: flex-start;
}
.flex-container3 {
    display: flex;
    flex-flow: row, wrap;
    justify-content: flex-start;
}
.flex-child {
    flex: auto;
}
.flex-child2 {
    flex-shrink: auto;
}
.panel-body {
    background: white;
}
.panel-heading {
    background-color: black;
}
.panel-default.panel-heading {
    background-color: white;
}
// Albeit good practice to rather use classes, I am using the ID of the element due to
// the content of the tab having an effect on how it looks, being displayed over the MAP.
#subheaderTabs {
    position: absolute;
	max-height:100%;
    z-index: 904; // NOTE: Making this >905 would cause subheaderTabs to take precedence above our accounts dropdown
	display:flex;
	flex-direction:column;
    //width: 30%;
}

.polygon-fade-in {
    transition: stroke-opacity 0.2s linear;
}

.marker-fade-in {
    transition: opacity 0.2s linear;
}

.myFade {
    transition: opacity linear 250ms;
    opacity: 1;
}
.myFade.ng-hide {
    opacity: 0;
}
.grow { transition: all .3s ease-in-out; }
.border-image {
    transition: .5s ease;
}
.border-image:hover{
    box-shadow: 0 0 0 1px #000000;
    transition: .5s ease;
}
.grow:hover { transform: scale(1.1); }
.photo-box {
    box-shadow: 0 0 0 10px white;
    margin-bottom: 10px;
    background-color: aliceblue;
}
#chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
.custom-marker-icon-center-div {
	position: absolute;
	width:100%;
	height:70%;
	top:0;
	left:0;
	display:flex;
	align-items:center;
	justify-content:center;
}

.custom-marker-icon {
	color: white;
}

