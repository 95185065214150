.custom-carousel-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	//height: 20em;
}

.custom-carousel-element {
	flex-grow: 1;
	height: 100%;
	padding: 0.5em;
}

.custom-carousel-element > div {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-carousel-element > div > img{
	height: auto;
	width: 100%;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
}
