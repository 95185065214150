.page-header {
    background: rgba(255,255,255,1);
}
.navbar-text {
    margin-left: 15px;
}

img.Crop-125 {
    width: 125px;
    height: 125px;
    border-radius: 3px;
    margin: 15px 15px 15px;
    cursor: pointer;
}

.spinloader{
    border: 16px solid #f3f3f3;
    border-top: 16px solid #323232; /* Grey */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


#big span.btn-xs {
    font-size: 14px;
}
#big input.input-xs {
    font-size: 14px;
}

img.largeCrop {
    width: auto;
    height: 225px;
    border-radius: 3px;
}

.photo {
    /*    background:#fff url(images/spinner.gif) center 30px no-repeat;*/
    border:1px solid rgba(0, 0, 0, .15);
    border-radius:3px;
    margin:0 10px 10px 0;
    position:relative;
}

.photo .photo-index {
    background:rgba(0, 0, 0, .9);
    color:#fff;
    position:absolute;
    height:100%;
    text-align:center;
    width:100%;
    z-index:99;
}

.photo .photo-index p:first-child {
    margin-top:10px;
}

.photo .photo-wrapper {
    min-height:60px;
}

.photo .photo-description {
    padding:15px;
}

.flex-center{
    display:flex;
    justify-content:center;
    align-content:center;
}

.refPhoto{
    width: 100%;
    height: 100%;
    max-height: 360px;
    max-width: 680px;
}


.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.viewport{
    overflow: auto;
    margin-top: 15px;
}
.lookup-results {
    margin-top: 2%;
    border-top: 1px solid #e5e5e5;
}

.flex-custom {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content:space-between;
}

.video-icon {
    cursor: pointer;
}
