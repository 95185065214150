.eventDetailContainer {
    position:absolute;
	top:100px;
    z-index:1030; //to exceed uib-accordion, leaflet controls AND carouselcontainer
    background-color: rgba(255,255,255,1);
    width: 35%; //be 1% less than #Events
    box-shadow: 0 3px 14px rgba(0,0,0,0.4);
    border-radius: 10px;
    padding: 1px;
    left: 2px;
    // height: 70%; //found that when making the screen smaller it fits better
    // max-height: 505px; //found that on a proper screen, it fits fully
    min-width: 300px;
    min-height: 300px;
    height: 435px;
    width: 450px;
    overflow:auto;

    .rg-right {
        margin-right: 0px;
    }

    .rg-bottom {
        margin-bottom: 0px;
    }
}

.fullHeight{
    height: 100%;
}
.autoHeight{
    height: auto;
}

.slick-image {
    transition: all 0.3s linear;
    margin-top: 5px;
    // width: 150px;
    //I am giving a percentage-based width BECAUSE our img is within an ng-repeated DIV that gets a variable
    //width given to it by SLICK. If our image has a fixed width it is possible that our images would exceed
    //its container's width and overlap
    height:100%;
    // max-width:15em;
    width:100%;
    display:block;
}
.slick-image:hover {
    transform: scale(1.05);
}
img.relatedToEvent{
    // border: 2px solid #1d70ab;
    // box-shadow: 0px 0px 3px 2px #1d70ab;
    box-shadow: 0px 0px 0px 2px #8d8e98;
}

.slick-prev:before, .slick-next:before {
    color: black;
}

.eventCarouselContainer .slick-next {
    right: -14px;
}
.eventCarouselContainer .slick-prev {
    left: -18px;
}



.grow { transition: all .3s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.grow-small { transition: all .3s ease-in-out; }
.grow-small:hover { transform: scale(1.03); }

.border-image {
    transition: .5s ease;
}

.border-image:hover{
    box-shadow: 0 0 0 1px #000000;
    transition: .5s ease;
}

.selected-event-close-button {
    color: black;
    padding-right: 5px !important;
    z-index: 7;
    position: absolute;
    right: 0px;
}

.selected-event-tab-content-wrapper {
    position:relative;
    width:100%;
    height: 100%;
    // overflow: auto;
}

.selected-event-tab-content-wrapper::-webkit-scrollbar { width:12px; }
.selected-event-tab-content-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.selected-event-tab-content-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.eventCarouselContainer {
    //position:absolute;
    z-index:1010; //to exceed uib-accordion AND leaflet zoomcontrols
    // z-index:1000; //to exceed uib-accordion
    // background-color: rgba(255,255,255,1);
    // box-shadow: 0 3px 14px rgba(0,0,0,0.4);
    // border-radius: 10px;
    // padding: 5px;
    left: 0px;
    //bottom: -25px;
    // bottom: -14px;
    width: 100%;
    // width: 95%;
    // margin: 0 auto; margin-top: 10px;
}

#selectedEventScheduleTable {
    // overflow: auto;
}

.selectedEventScheduleCheckbox {
    padding: 1px;
    box-shadow: 0px 0px 0px 2px #c1c1c1;
    font-size: 8px;
    border-radius: 1px;
    color: #8e8c8c;

}

.rotateTab {
    transform: rotate(270deg);
    margin-top: 1em;
    margin-bottom: 1em;
}

.rotateTab>.nav-tabs>li.active>a {
    box-shadow: 0 0px 0 #57889c !important;
}

/**
 * Getting  strange behaviour where the photos are displayed center of the screen
 * As far as I remember, this started happening on its own so I presume it has
 * to do with something being updated.
 */
#dashboard-carousel > div.slick-list > div.slick-track {
    width: 100% !important;
}

.action-event-table {
    table {
        border-collapse: collapse;
    }

    td, th {
        // border: 1px solid rgba(67, 135, 191, 0.29);
        padding: 0.5rem;
        text-align: center;
    }

    .compulsory-step {
        text-decoration: underline;
        text-decoration-color: #4387bf;
    }
}

.action-step {
    transition: all 0.3s;
}

.active-step {
    // box-shadow: 0px 0px 7px 0px #3b9ff4;
    box-shadow: inset 0px 0px 1px 0px #3b9ff4;
}

.step-moved-down {
    transform: translateY(2em);
}


.action-steps-container {
    height: 25em;
    width: 50%;
    margin:auto;
	overflow: hidden auto !important;
}
.action-steps-indicators {
    color: #53585f;
    font-size: 4em;
    display: flex;
    justify-content: center;
}
.action-steps-indicators span.active {
    color:#4387bf !important;
}
.action-event-container > div.rn-carousel-controls > span.rn-carousel-control {
    font-size: 4rem;
}

#styledEditor {
	height: 120px;
	border: 1px solid #cccccc;
	padding: 5px;
	font-family: Tahoma, sans-serif;
    background-color: #fbfbef;
    min-width: 100%;
}

a.disabled {
   pointer-events: none;
   cursor: default;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.half-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 20%;
  opacity: 0;
  transition: .5s ease;
  background-color: white;
  cursor: pointer;
}

.left {
 left: 0;
 background: linear-gradient(to left, rgba(255,0,0,0), rgba(255,255,255,0.5));
}
.right {
 right: 0;
 background: linear-gradient(to right, rgba(255,0,0,0), rgba(255,255,255,0.5));
}

.half-overlay:hover {
opacity: 1;
}

.hover-icon {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  // left: 50%;
  font-size: 3em;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.panicPhoto {
    // box-shadow: 4px 4px 2px #e61610;
    background-color: #e61610;
	opacity : 0.8;
}

.requestPhoto {
    // box-shadow: 4px 4px 2px #e61610;
    background-color: #064d36;
	opacity : 0.8;
}

.normalPhoto {
    // box-shadow: 4px 4px 2px #21826f;
    background-color: #21826f;
	opacity : 0.8;
}

.normalPhoto.currentImage{
	border: 5px solid #21826f;
}

.requestPhoto.currentImage{
	border: 5px solid #064d36;
}

.panicPhoto.currentImage{
	border: 5px solid #e61610;
}

.currentImage {
    box-shadow: 5px 5px 5px;
	opacity : 1;
}

.currentImage > div{
	font-weight: bolder;
}

body > .select2-container.open{
	z-index: 99999999
}

.small-column {
    width: 32px;

}