
.account-logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 220px;
	height: 50px;
}

.account-logo {
	max-width:220px;
	max-height:50px;
}
