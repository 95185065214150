.procedure-field-container {
    height: 38em;
    width: 70%;
    margin:auto;
	overflow-y:auto !important;
}
.procedure-steps-indicators {
    color: #53585f;
    font-size: 4em;
}
.procedure-steps-indicators span.active {
    color:#4387bf !important;
}
.procedure-summary-table {
    table {
        border-collapse: collapse;
    }

    td, th {
        border: 2px solid rgba(67, 135, 191, 0.29);
        padding: 0.5rem;
        text-align: center;
    }
}

div.ui-select-container.ng-invalid > a {
    border-color: indianred !important;
}
