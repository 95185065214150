.route-detail-distance-line {
    transition: opacity 0.3s ease;
    stroke: rgb(51, 136, 255);
    stroke-opacity: 1;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 5, 10;
    fill: none;
}

.route-detail-popup > div.leaflet-popup-content-wrapper > div.leaflet-popup-content {
    margin: 4px 4px;
}
