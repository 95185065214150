@import './new-sop/new-sop';

.toggled-show-sites {
    box-shadow: 0px 0px 2px 0px #4387bf;
}


.sop-tag{
    margin:0.5em;
    font-size: 1em;
}
