
.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 10px auto 10px;
  border-radius: 3px;
}

.strength-meter:before,
.strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 5px 0 5px;
  position: absolute;
  width: calc(((100% - 20px) / 5) + 10px);
  z-index: 10;
}

.strength-meter:before {
  left: calc((100% - 20px) / 5);
}

.strength-meter:after {
  right: calc((100% - 20px) / 5);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill.strength-0 {
  background: darkred;
  width: 20%;
}

.strength-meter-fill.strength-1 {
  background: orangered;
  width: 40%;
}

.strength-meter-fill.strength-2 {
  background: orange;
  width: 60%;
}

.strength-meter-fill.strength-3 {
  background: yellowgreen;
  width: 80%;
}

.strength-meter-fill.strength-4 {
  background: green;
  width: 100%;
}

.password-feedback,
.password-suggetion {
  font-size: 70%;
  font-weight: 400;
  color: #6c757d !important;
  display: block;
  margin-top: 0.25rem;
}
