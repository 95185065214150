@import "./units-list/minimap-modal/minimap-modal";

.unitsContainer {
  padding: 10px 14px;
  position: relative;
}

.myGrid {
    width: 1000px;
    height: 500px;
  }

//This hides the 'Name' option from the select. It is done that the
//user cannot remove this perticular column.
.select2-locked {
  display: none;
}

.header.gu-over-accept {
  background-color: green;
}

.header.gu-over-decline {
  background-color: red;
}

.formFade {
    transition: opacity linear 250ms;
    opacity: 1;
}

.formFade.ng-hide {
    opacity: 0;
}

.units-multiple-select {
    ul > span > li {
        background-color: rgba(66, 133, 244, 0.78) !important;
        border-color: rgb(142, 170, 197) !important;
    }
}

.units-select-choices {
    li > ul > li.select2-highlighted {
        background: rgba(66, 133, 244, 0.78) !important;
    }
}
