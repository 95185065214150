.site-detail-container {
    height: 100%
}
.site-detail-map {
    height: 100%;
    width: 100%;
}
.selected-site-content {
    // border-left: 3px solid #4285f4;
}
.selected-site-info {
    font-size: 1.5em;
    letter-spacing: -0.2px;
    margin: 5px;
}
.site-info-icon {
    color: rgb(66,133,244);
}
.selected-site-heading {
    width: 100%;
    background-color: rgb(66,133,244);
    color: white;
    height: 6em;
    padding-left: 2em;
}
.selected-site-sub-heading {
    width: 100%;
    height: 6em;
    padding-left: 2em;
}
.tiny-tooltip {
    opacity: 1;
    color: rgb(0, 85, 140);
    padding: 1px;
    transition: opacity 0.5s linear;
}
.tooltip-faded {
    opacity: 0 !important;
}
