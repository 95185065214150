.switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 30px;

    // ============================  Toggle  ================================ //
    // ---------------------------------------------------------------------- //
    .left-label {
        flex-grow: 1;
    }
    .right-label {
        flex-grow: 1;
        text-align: right;
    }
    .toggle-switch {
        flex-grow: 1;
        height: 30px;
        // width: 30%;
        // position: relative;
    }

    .toggle-switch > input[type=checkbox]{
    	height: 0;
    	width: 0;
    	visibility: hidden;
    }

    .toggle-switch > label {
    	cursor: pointer;
    	text-indent: -9999px;
        top: -19px;
        margin-bottom: 0px;
    	width: 60px;
    	height: 30px;
    	background: grey;
    	display: block;
    	border-radius: 30px;
    	position: relative;
    }

    .toggle-switch > label:after {
    	content: '';
    	position: absolute;
    	top: 3px;
    	left: 3px;
    	width: 24px;
    	height: 24px;
    	background: #fff;
    	border-radius: 24px;
    	transition: 0.5s;
    }

    .toggle-switch > input:checked + label {
    	background: #bada55;
    }

    .toggle-switch > input:checked + label:after {
    	left: calc(100% - 3px);
    	transform: translateX(-100%);
    }

    .toggle-switch > label:active:after {
    	width: 35px;
    }
}

// ===============================  Colors ================================== //
// -------------------------------------------------------------------------- //
.label-on {
    background-color: #82c785;
}

// ===========================  On-Off switch =============================== //
// -------------------------------------------------------------------------- //
.onoff-switch > input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}
.onoff-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
    text-align: left;
}
.onoff-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.onoff-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}

.onoff-switch > label:disabled::before {
    background: #333;
}

.onoff-switch > label:disabled::after {
    background: #333;
}

.onoff-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.onoff-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

.onoff-label {
    flex-grow: 1
}
.onoff-switch {
    flex-grow: 1
}

// ============================  Mobile mode ================================ //
// -------------------------------------------------------------------------- //
@media only screen and (max-width: 400px) {
    .switch-container {
        margin: 10px 0px;
    }
}
