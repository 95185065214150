$spacing: 14px;
$min-section-width: 160px;

.events-page {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;
	align-content: space-around;
	//padding: $spacing/2;

	.resizable-row {
		min-height: 50px;
		flex: 1;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-around;
		align-content: space-around;

		&.resizable {
			flex: 0 0 300px;
		}

		section {
			max-width: calc(90% - $min-section-width);
		}
	}

	section {
		box-sizing: border-box;
		border-radius: 10px;
		padding: 1.25em 1.75em;
		background: #ddd;
		border: $spacing/2 solid #fff;
		flex: 1;
		min-width: $min-section-width;

		&.resizable {
			flex: 0 0 300px;
		}
	}

	// **********************************************************************
	// directive styles
	// **********************************************************************
	.resizable {
		position: relative;

		&.no-transition {
			transition: none !important;
		}
	}

	.rg-right,
	.rg-left,
	.rg-top,
	.rg-bottom {
		display: block;
		width: $spacing;
		height: $spacing;
		line-height: $spacing;
		position: absolute;
		z-index: 1;
		@include user-select(none);

		span {
			position: absolute;
			box-sizing: border-box;
			display: block;
			border: 1px solid #ccc;
		}
	}

	.rg-right,
	.rg-left {
		span {
			border-width: 0 1px;
			top: 50%;
			margin-top: -10px;
			margin: -10px 0 0 $spacing/4;
			height: 20px;
			width: $spacing/2;
		}
	}

	.rg-top,
	.rg-bottom {
		span {
			border-width: 1px 0;
			left: 50%;
			margin: $spacing/4 0 0 -10px;
			width: 20px;
			height: $spacing/2;
		}
	}

	.rg-top {
		cursor: row-resize;
		width: 100%;
		top: 0;
		left: 0;
		margin-top: -$spacing/2;
	}

	.rg-right {
		cursor: col-resize;
		height: 100%;
		right: 0;
		top: 0;
		margin-right: -$spacing;
	}

	.rg-bottom {
		cursor: row-resize;
		width: 100%;
		bottom: 0;
		left: 0;
		margin-bottom: -$spacing/2;
	}

	.rg-left {
		cursor: col-resize;
		height: 100%;
		left: 0;
		top: 0;
		margin-left: -$spacing;
	}

}

table.scrollable-table {
	display: flex;
	flex-flow: column;
	height: calc(100% - 88px); //Subtract 55px for paging buttons
	width: 100%;

	thead:first-child > tr:first-child > th:first-child {
		width: 34px;
	}

	thead {
		/* head takes the height it requires, 
        and it's not scaled when table is resized */
		flex: 0 0 auto;
		width: calc(100% - 0.9em);
	}

	tbody {
		/* body takes all the remaining available space */
		flex: 1 1 auto;
		display: block;
		overflow-y: scroll;
	}

	tbody tr {
		width: 100%;
	}

	thead,
	tbody tr {
		display: table;
		table-layout: fixed;
	}
}

.photo-container {
	flex: 1;
	min-width: 150px;
	min-height: 150px;
	margin: 0.5em;
	background-color: white;

	// photo-panel {
	//     display: flex;
	//     align-items: center;
	//     justify-content: center;

	//     .photo-wrapper {
	//         flex:1
	//     }
	// }
}

table.selectable-rows {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	tr.selected-row {
		background: #3b9ff34a;
	}
}

.text-truncate {
	text-overflow: ellipsis;
}